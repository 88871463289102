import api from "./api";

const updateUser = (payload) => {
  return api.post("/api/user/update", payload);
}

const updateUsername = (payload) => {
  return api.post("/api/user/updateUsername", payload);
}

const updatePassword = (payload) => {
  return api.post("/api/user/updatePassword", payload);
}

export default {
  updateUser,
  updateUsername,
  updatePassword,
};