import React, { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FaAngleDown, FaRegUserCircle } from 'react-icons/fa';
import { FiLogOut } from 'react-icons/fi';
import BlankAvatarSVG from '../../assets/svgs/blank.svg';
import { useTranslation } from 'react-i18next';

const HeaderDropDown = ({ logout }) => {
  const { t } = useTranslation();
  const navigation = useNavigate();
  const { user: currentUser } = useSelector((state) => state.auth);

  return (
    <div className="text-right">
      <Menu as="div" className="relative text-left">
        <Menu.Button className="flex w-full justify-center items-center bg-transparent">
          <FaAngleDown
            className="h-4 w-4 text-black"
            aria-hidden="true"
          />
        </Menu.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-[0px] mt-[20px] w-[270px] origin-top-right z-10 rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none pb-2">
            <div className='w-full flex flex-col justify-center items-center py-[30px]'>
              <img src={
                currentUser && currentUser.avatar ?
                  currentUser.avatar :
                  BlankAvatarSVG
              } className='rounded-full h-[80px] w-[80px]' />
              <div className='flex flex-row justify-center items-center font-SenSans text-[#3d474d] text-[20px] space-x-1 pt-2'>
                <span>{currentUser?.firstname}</span>
                <span>{currentUser?.lastname}</span>
              </div>
              <span className='text-[rgb(138,148,153)] text-[16px] font-RNSSanzMedium'>
                {currentUser?.email}
              </span>
            </div>
            <div className="font-RNSSanzMedium px-2">
              <Menu.Item>
                {({ active }) => (
                  <div className={`${active ? 'bg-[rgba(230,247,255,1)]' : ''} flex flex-row justify-start items-center space-x-[10px] text-[#3d474d] p-4 rounded-md`}
                    onClick={() => navigation("/profile")}
                  >
                    <FaRegUserCircle className='text-[18px]' />
                    <span className='text-[16px]'>{t("header.dropdown.profile")}</span>
                  </div>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <div className={`${active ? 'bg-[rgba(230,247,255,1)]' : ''} flex flex-row justify-start items-center space-x-[10px] text-[#3d474d] p-4 rounded-md`}
                    onClick={() => logout()}
                  >
                    <FiLogOut className='text-[18px]' />
                    <span className='text-[16px]'>{t("header.dropdown.logout")}</span>
                  </div>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  )
}

export default HeaderDropDown;