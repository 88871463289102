import {
  GET_ALL_USERS_SUCCESS,
  GET_ALL_USERS_FAIL,
  SET_MESSAGE
} from "./types";
import AdminService from "../services/admin.service";

export const getUsers = (searchParam, pageSize, pageNumber, column, sortDirection) => (dispatch) => {
  return AdminService.getUsers(searchParam, pageSize, pageNumber, column, sortDirection).then(
    (response) => {
      if (response.data.success) {
        dispatch({
          type: GET_ALL_USERS_SUCCESS,
          payload: response.data.data
        });
        return Promise.resolve();
      } else {
        dispatch({ type: GET_ALL_USERS_FAIL });
        return Promise.reject();
      }
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch({ type: GET_ALL_USERS_FAIL });
      dispatch({
        type: SET_MESSAGE,
        payload: {
          success: false,
          message: message
        }
      });
      return Promise.reject();
    }
  )
}

export const addUser = (payload) => (dispatch) => {
  return AdminService.addUser(payload).then(
    (response) => {
      dispatch({
        type: SET_MESSAGE,
        payload: {
          success: true,
          message: response.data.message
        }
      });
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch({
        type: SET_MESSAGE,
        payload: {
          success: false,
          message: message
        }
      });
      return Promise.reject();
    }
  )
}

export const updateUser = (payload) => (dispatch) => {
  return AdminService.updateUser(payload).then(
    (response) => {
      dispatch({
        type: SET_MESSAGE,
        payload: {
          success: true,
          message: response.data.message
        }
      });
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch({
        type: SET_MESSAGE,
        payload: {
          success: false,
          message: message
        }
      });
      return Promise.reject();
    }
  )
}

export const deleteUser = (payload) => (dispatch) => {
  return AdminService.deleteUser(payload).then(
    (response) => {
      dispatch({
        type: SET_MESSAGE,
        payload: {
          success: true,
          message: response.data.message
        }
      });
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch({
        type: SET_MESSAGE,
        payload: {
          success: false,
          message: message
        }
      });
      return Promise.reject();
    }
  )
}