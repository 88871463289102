import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
  PDFViewer,
} from "@react-pdf/renderer";
import Logo from "../../../assets/logo.png";
import CheckedImg from "../../../assets/checked.png";
import UnCheckedImg from "../../../assets/unchecked.png";
import SingImg from "../../../assets/sign.png";
import { Insurances } from "../../components/Insurances";
import { Products } from "../../components/Products";
import moment from "moment";
import "moment/locale/de";

const styles = StyleSheet.create({
  page: {
    backgroundColor: "#ffffff",
    padding: "30px 40px",
  },
  rowAlignCenter: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    paddingBottom: "5px",
  },
  rowAlignStart: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    paddingBottom: "5px",
  },
  colJustifyStart: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    paddingBottom: "5px",
  },
  colJustifyCenter: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    paddingBottom: "5px",
  },
  txtTitle: {
    fontSize: "11px",
    paddingBottom: "5px",
  },
  txtValue: {
    fontSize: "8px",
    fontWeight: 800,
    paddingBottom: "5px",
  },
  tableHeader: {
    fontWeight: 800,
    textAlign: "center",
    fontSize: "8px",
    padding: "3px 5px",
  },
  tableText: {
    textAlign: "center",
    fontSize: "8px",
    padding: "3px 5px",
  },
});

Font.register({
  family: "Open Sans",
  fonts: [
    {
      src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf",
    },
    {
      src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-800.ttf",
      fontWeight: 800,
    },
  ],
});

const NewPdfDocument = ({ row }) => {
  let insuranceObj = Insurances.find((obj) => obj.name === row.insurance);
  let orderProducts = JSON.parse(row.products);
  let productsTableData = [];
  let totalProductPrice = 0;
  let birthDay = row.startDate
    ? moment(row.startDate).format("DD.MM.YYYY")
    : " ";
  let createdAt = moment(row.createdAt).format("DD.MM.YYYY");
  let createdAtDate = moment(new Date()).format("DD.MM.YYYY");
  let createdAtMonth = moment(new Date()).locale("de").format("MMMM");
  if (orderProducts.length) {
    for (let i = 0; i < Products.length; i++) {
      let object = {};
      object["count"] = orderProducts.reduce(
        (acc, cur) => (cur.title === Products[i].title ? ++acc : acc),
        0
      );
      object["totalPrice"] =
        Number(Products[i].price) *
          orderProducts.reduce(
            (acc, cur) => (cur.title === Products[i].title ? ++acc : acc),
            0
          ) ==
        0
          ? 0
          : (
              Number(Products[i].price) *
              orderProducts.reduce(
                (acc, cur) => (cur.title === Products[i].title ? ++acc : acc),
                0
              )
            ).toFixed(2);
      productsTableData.push(object);
    }

    for (let j = 0; j < productsTableData.length; j++) {
      totalProductPrice += Number(productsTableData[j].totalPrice);
    }
  }

  console.log({ row });

  return (
    // <PDFViewer width={1200} height={1200}>
    <Document>
      <Page style={styles.page}>
        <View
          style={{ ...styles.rowAlignStart, justifyContent: "space-between" }}
        >
          <View style={styles.rowAlignCenter}>
            <Image
              source={Logo}
              style={{
                width: "50px",
                height: "50px",
                objectFit: "contain",
              }}
            />
            <Text
              style={{
                fontSize: "18px",
                color: "#6ea13b",
              }}
            >
              Pflege im Karton
            </Text>
          </View>
          <Text
            style={{
              fontSize: 12,
              fontWeight: "800",
              textAlign: "right",
            }}
          >
            Wechselerklärung Pflegehilfsmittelversorgung
          </Text>
        </View>
        <View style={styles.colJustifyStart}>
          <Text
            style={{
              ...styles.txtTitle,
              lineHeight: "1.5",
              fontSize: 10,
              width: "100%",
              marginTop: 10,
            }}
          >
            Dieses Formular dient der Kenntnissnahme Ihrer Pflegekasse über
            einen Lieferantenwechsel in Bezug auf die Versorgung mit zum
            Verbrauch bestimmten Pflegehilfsmitteln gemäß § 78 Abs. 1, i.V.m. §
            40 Abs. 2 SGB XI (analog Anlage 4)
          </Text>
          <View style={{ padding: "10px 0px 25px 0px", width: "100%" }}>
            <Text
              style={{ fontSize: 11, fontWeight: "800", textAlign: "left" }}
            >
              Name und Anschrift der Pflegekasse
            </Text>
            <Text
              style={{
                borderBottom: "1px solid black",
                width: "45%",
                padding: "3px",
                paddingTop: 10,
                fontSize: 9,
              }}
            >
              {row.insurance ? row.insurance : " "}
            </Text>
            <Text
              style={{
                borderBottom: "1px solid black",
                width: "45%",
                padding: "3px",
                paddingTop: 10,
                fontSize: 9,
              }}
            >
              {insuranceObj.ik}
            </Text>
            <Text
              style={{
                borderBottom: "1px solid black",
                width: "45%",
                padding: "3px",
                paddingTop: 10,
                fontSize: 9,
              }}
            >
              {insuranceObj.address}
            </Text>
          </View>
          <View
            style={{
              border: "1px solid #0ab39c",
              padding: "5px 10px 10px 10px",
              width: "100%",
            }}
          >
            <Text
              style={{
                fontSize: 11,
                fontWeight: "800",
                textAlign: "left",
                paddingBottom: 10,
              }}
            >
              Name der/des Versicherten/Pflegebedürftigen
            </Text>
            <View
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "flex-start",
                gap: "5px",
              }}
            >
              <View
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  width: "50%",
                  gap: 5,
                }}
              >
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <Image
                    source={row.type === "woman" ? CheckedImg : UnCheckedImg}
                    style={{
                      width: "18px",
                      height: "18px",
                      objectFit: "contain",
                    }}
                  />
                  <Text
                    style={{
                      fontSize: 9,
                      width: "12%",
                      padding: "2px 0px 2px 5px",
                    }}
                  >
                    Frau
                  </Text>
                  <Image
                    source={row.type === "man" ? CheckedImg : UnCheckedImg}
                    style={{
                      width: "18px",
                      height: "18px",
                      objectFit: "contain",
                    }}
                  />
                  <Text
                    style={{
                      fontSize: 9,
                      color: "black",
                      width: "12%",
                      padding: "2px 0px 2px 5px",
                    }}
                  >
                    Herr
                  </Text>
                  <Text
                    style={{
                      fontSize: 9,
                      width: "18%",
                      padding: "2px 0px 2px 0px",
                    }}
                  >
                    Vorname:
                  </Text>
                  <Text
                    style={{
                      borderBottom: "1px solid black",
                      width: "42%",
                      padding: "2px",
                      fontSize: 9,
                    }}
                  >
                    {row.firstname ? row.firstname : " "}
                  </Text>
                </View>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <Text
                    style={{
                      fontSize: 9,
                      width: "18%",
                      padding: "2px 0px 2px 0px",
                    }}
                  >
                    Straße/Nr.:
                  </Text>
                  <Text
                    style={{
                      borderBottom: "1px solid black",
                      width: "80%",
                      padding: "2px",
                      fontSize: 9,
                    }}
                  >
                    {row.street ? row.street : " "} {row.houseNo || " "}
                  </Text>
                </View>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <Text
                    style={{
                      fontSize: 9,
                      width: "18%",
                      padding: "2px 0px 2px 0px",
                    }}
                  >
                    PLZ/Ort:
                  </Text>
                  <Text
                    style={{
                      borderBottom: "1px solid black",
                      width: "80%",
                      padding: "2px",
                      fontSize: 9,
                    }}
                  >
                    {row.postcode + " / " + row.location}
                  </Text>
                </View>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <Text
                    style={{
                      fontSize: 9,
                      width: "18%",
                      padding: "2px 0px 2px 0px",
                    }}
                  >
                    Telefon:
                  </Text>
                  <Text
                    style={{
                      borderBottom: "1px solid black",
                      width: "80%",
                      padding: "2px",
                      fontSize: 9,
                    }}
                  >
                    {row.phone ? row.phone : " "}
                  </Text>
                </View>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <Text
                    style={{
                      fontSize: 9,
                      width: "18%",
                      padding: "2px 0px 2px 0px",
                    }}
                  >
                    E-Mail:
                  </Text>
                  <Text
                    style={{
                      borderBottom: "1px solid black",
                      width: "80%",
                      padding: "2px",
                      fontSize: 9,
                    }}
                  >
                    {row.email ? row.email : " "}
                  </Text>
                </View>
              </View>

              <View
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  width: "50%",
                  gap: 5,
                }}
              >
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <Text
                    style={{
                      fontSize: 9,
                      width: "15%",
                      padding: "2px 0px 2px 0px",
                    }}
                  >
                    Name:
                  </Text>
                  <Text
                    style={{
                      borderBottom: "1px solid black",
                      width: "90%",
                      padding: "2px",
                      fontSize: 9,
                    }}
                  >
                    {row.lastname ? row.lastname : " "}
                  </Text>
                </View>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <Text
                    style={{
                      fontSize: 9,
                      width: "45%",
                      padding: "2px 0px 2px 0px",
                    }}
                  >
                    Geburtsdatum:
                  </Text>
                  <Text
                    style={{
                      borderBottom: "1px solid black",
                      width: "80%",
                      padding: "2px",
                      fontSize: 9,
                    }}
                  >
                    {birthDay}
                  </Text>
                </View>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <Text
                    style={{
                      fontSize: 9,
                      width: "45%",
                      padding: "2px 0px 2px 0px",
                    }}
                  >
                    Pflegegrad:
                  </Text>
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "80%",
                      // borderBottom: '1px solid black'
                    }}
                  >
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        marginRight: 5,
                      }}
                    >
                      <Image
                        source={row.degree == 1 ? CheckedImg : UnCheckedImg}
                        style={{
                          width: "18px",
                          height: "18px",
                          objectFit: "contain",
                        }}
                      />
                      <Text style={{ fontSize: 10, paddingLeft: 2 }}>1</Text>
                    </View>
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        marginRight: 5,
                      }}
                    >
                      <Image
                        source={row.degree == 2 ? CheckedImg : UnCheckedImg}
                        style={{
                          width: "18px",
                          height: "18px",
                          objectFit: "contain",
                        }}
                      />
                      <Text style={{ fontSize: 10, paddingLeft: 2 }}>2</Text>
                    </View>
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        marginRight: 5,
                      }}
                    >
                      <Image
                        source={row.degree == 3 ? CheckedImg : UnCheckedImg}
                        style={{
                          width: "18px",
                          height: "18px",
                          objectFit: "contain",
                        }}
                      />
                      <Text style={{ fontSize: 10, paddingLeft: 2 }}>3</Text>
                    </View>
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        marginRight: 5,
                      }}
                    >
                      <Image
                        source={row.degree == 4 ? CheckedImg : UnCheckedImg}
                        style={{
                          width: "18px",
                          height: "18px",
                          objectFit: "contain",
                        }}
                      />
                      <Text style={{ fontSize: 10, paddingLeft: 2 }}>4</Text>
                    </View>
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        marginRight: 5,
                      }}
                    >
                      <Image
                        source={row.degree == 5 ? CheckedImg : UnCheckedImg}
                        style={{
                          width: "18px",
                          height: "18px",
                          objectFit: "contain",
                        }}
                      />
                      <Text style={{ fontSize: 10, paddingLeft: 2 }}>5</Text>
                    </View>
                  </View>
                </View>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <Text
                    style={{
                      fontSize: 9,
                      width: "45%",
                      padding: "2px 0px 2px 0px",
                    }}
                  >
                    Pflegekasse:
                  </Text>
                  <Text
                    style={{
                      borderBottom: "1px solid black",
                      width: "80%",
                      padding: "2px",
                      fontSize: 8,
                    }}
                  >
                    {row.insurance ? row.insurance : " "}
                  </Text>
                </View>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <Text
                    style={{
                      fontSize: 9,
                      width: "45%",
                      padding: "2px 0px 2px 0px",
                    }}
                  >
                    Versicherungs-Nr.:
                  </Text>
                  <Text
                    style={{
                      borderBottom: "1px solid black",
                      width: "80%",
                      padding: "2px",
                      fontSize: 9,
                    }}
                  >
                    {row.insuranceNo ? row.insuranceNo : " "}
                  </Text>
                </View>
              </View>
            </View>
          </View>

          <Text
            style={{
              fontSize: 11,
              fontWeight: "800",
              textAlign: "left",
              paddingTop: 20,
            }}
          >
            Sehr geehrte Damen und Herren,
          </Text>
          <Text
            style={{
              ...styles.txtTitle,
              lineHeight: "1.5",
              marginTop: 10,
              fontSize: 10,
            }}
          >
            hiermit kündige ich den Bezug der zum Verbrauch bestimmten
            Pflegehilfsmittel bei meinem bisherigen Leistungserbringer zum{" "}
            <Text style={{ textDecoration: "underline" }}>{createdAt}</Text>.
            Ich mache von meinem Wahlrecht Gebrauch und möchte ab dem{" "}
            <Text style={{ textDecoration: "underline" }}>{createdAt}</Text> die
            Pflegehilfsmittel für mich bzw. meine Pflegeperson von folgendem
            Leistungserbringer beziehen:
          </Text>

          <View
            style={{
              ...styles.rowAlignStart,
              paddingBottom: 0,
              marginTop: 10,
              marginBottom: 10,
            }}
          >
            <View
              style={{
                ...styles.colJustifyStart,
                width: "50%",
                paddingBottom: 0,
              }}
            >
              <View
                style={{
                  ...styles.rowAlignStart,
                  alignItems: "center",
                  paddingBottom: 0,
                }}
              >
                <Text
                  style={{
                    ...styles.txtTitle,
                    fontSize: 10,
                    fontWeight: "800",
                  }}
                >
                  Pflege im Karton
                </Text>
              </View>
              <Text style={{ ...styles.txtTitle, fontSize: 10 }}>
                Inobis Care GmbH{" "}
              </Text>
              <Text style={{ ...styles.txtTitle, fontSize: 10 }}>
                Bergmannstraße 2A,{" "}
              </Text>
              <Text style={{ ...styles.txtTitle, fontSize: 10 }}>
                46045 Oberhausen{" "}
              </Text>
            </View>
            <View
              style={{
                ...styles.colJustifyStart,
                width: "50%",
                paddingBottom: 0,
              }}
            >
              <Text
                style={{ ...styles.txtTitle, fontSize: 10, fontWeight: "800" }}
              >
                IK-Nr. des Leistungserbringers:
              </Text>
              <Text style={{ ...styles.txtTitle, fontSize: 10 }}>
                330508979
              </Text>
            </View>
          </View>
          <Text
            style={{
              ...styles.txtTitle,
              lineHeight: "1.5",
              fontSize: 10,
              width: "100%",
              marginTop: 5,
            }}
          >
            Bitte übertragen Sie die bisherige Kostenzusage auf den neuen
            Leistungserbringer. Vielen Dank.
          </Text>
          <Text
            style={{
              ...styles.txtTitle,
              lineHeight: "1.5",
              fontSize: 10,
              width: "100%",
              marginTop: 5,
            }}
          >
            Mit freundlichen Grüßen
          </Text>

          <View
            style={{
              ...styles.rowAlignStart,
              paddingBottom: 0,
              marginTop: 10,
              marginBottom: 10,
            }}
          >
            <View
              style={{
                ...styles.rowAlignStart,
                justifyContent: "space-between",
                alignItems: "flex-end",
                width: "100%",
                paddingTop: 10,
              }}
            >
              <View
                style={{
                  ...styles.colJustifyCenter,
                  alignItems: "center",
                  width: "35%",
                }}
              >
                <Text
                  style={{
                    ...styles.txtTitle,
                    width: "100%",
                    textAlign: "start",
                    paddingTop: "5px",
                  }}
                >
                  {createdAt}
                </Text>
                <View
                  style={{
                    borderBottom: "1px solid black",
                    width: "100%",
                    marginTop: 5,
                    height: 1,
                  }}
                ></View>
                <Text
                  style={{
                    ...styles.txtTitle,
                    width: "100%",
                    textAlign: "start",
                    paddingTop: "5px",
                  }}
                >
                  Ort/Datum
                </Text>
              </View>
              <View
                style={{
                  ...styles.colJustifyCenter,
                  alignItems: "center",
                  width: "60%",
                }}
              >
                <View>
                  <Image
                    source={row.signImage}
                    style={{
                      maxHeight: "70px",
                      // width: "auto",
                      // height: "400px",
                      // objectFit: "contain",
                    }}
                  />
                </View>
                <View
                  style={{
                    borderBottom: "1px solid black",
                    width: "100%",
                    marginTop: 5,
                  }}
                ></View>
                <Text
                  style={{
                    ...styles.txtTitle,
                    width: "100%",
                    textAlign: "start",
                    paddingTop: "5px",
                  }}
                >
                  Unterschrift Versicherte(r) oder Bevollmächtigte(r)
                </Text>
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
    // </PDFViewer>
  );
};

export default NewPdfDocument;
