import {
  USER_UPDATE_SUCCESS,
  USER_UPDATE_FAIL,
  USER_UPDATE_NAME_SUCCESS,
  USER_UPDATE_NAME_FAIL,
  USER_UPDATE_PASSWORD_SUCCESS,
  USER_UPDATE_PASSWORD_FAIL,
  SET_MESSAGE
} from "./types";
import UserService from "../services/user.service";
import TokenService from "../services/token.service";

export const updateUser = (payload) => (dispatch) => {
  return UserService.updateUser(payload).then(
    (response) => {
      dispatch({
        type: USER_UPDATE_SUCCESS,
        payload: payload.avatar
      });
      let user = TokenService.getUser();
      user.avatar = payload.avatar;
      TokenService.setUser(user);
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch({ type: USER_UPDATE_FAIL });
      dispatch({
        type: SET_MESSAGE,
        payload: {
          success: false,
          message: message
        }
      });
      return Promise.reject();
    }
  )
}

export const updateUsername = (payload) => (dispatch) => {
  return UserService.updateUsername(payload).then(
    (response) => {
      dispatch({
        type: USER_UPDATE_NAME_SUCCESS,
        payload: payload
      })
      dispatch({
        type: SET_MESSAGE,
        payload: {
          success: true,
          message: response.data.message
        }
      });
      let user = TokenService.getUser();
      user.firstname = payload.firstname;
      user.lastname = payload.lastname;
      user.type = payload.type;
      user.street = payload.street;
      user.location = payload.location;
      user.postcode = payload.postcode;
      user.phone = payload.phone;
      user.houseNo = payload.houseNo;
      TokenService.setUser(user);
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch({ type: USER_UPDATE_NAME_FAIL });
      dispatch({
        type: SET_MESSAGE,
        payload: {
          success: false,
          message: message
        }
      });
      return Promise.reject();
    }
  )
}

export const updatePassword = (payload) => (dispatch) => {
  return UserService.updatePassword(payload).then(
    (response) => {
      if (response.data.success) {
        dispatch({ type: USER_UPDATE_PASSWORD_SUCCESS });
        dispatch({
          type: SET_MESSAGE,
          payload: {
            success: true,
            message: response.data.message
          }
        });
        return Promise.resolve();
      } else {
        dispatch({ type: USER_UPDATE_PASSWORD_FAIL });
        dispatch({
          type: SET_MESSAGE,
          payload: {
            success: false,
            message: response.data.message
          }
        });
        return Promise.reject();
      }
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch({ type: USER_UPDATE_PASSWORD_FAIL });
      dispatch({
        type: SET_MESSAGE,
        payload: {
          success: false,
          message: message
        }
      });
      return Promise.reject();
    }
  )
}