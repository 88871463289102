import React, { useContext, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { BsArrowLeft } from 'react-icons/bs';
import './data.css';
import { ConnectContext } from '../../provider/ConnectProvider';
import { useNavigate } from 'react-router-dom';
import { validateEmail } from '../components/Validate';
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import { isValidPhoneNumber } from 'react-phone-number-input'

const degrees = [1, 2, 3, 4, 5, 0];

const Section = () => {
  const [searchParams] = useSearchParams();
  const ref = searchParams.get('ref') || '';
  const navigation = useNavigate();
  const [isDisable, setIsDisable] = useState(true);
  const [emailError, setEmailError] = useState(null);
  const [phoneError, setPhoneError] = useState(null);
  const [showAddress, setShowAddress] = useState(false);
  const [degreeWarning, setDegreeWarning] = useState(null);

  const {
    type, setType,
    interval, setInterval,
    firstname, setFirstname,
    lastname, setLastname,
    street, setStreet,
    street2, setStreet2,
    company, setCompany,
    houseNo, setHouseNo,
    houseNo2, setHouseNo2,
    postcode, setPostcode,
    postcode2, setPostcode2,
    location, setLocation,
    location2, setLocation2,
    degree, setDegree,
    phone, setPhone,
    email, setEmail,
    referral, setReferral,
    setCompletedSecondStep,
    completedFirstStep
  } = useContext(ConnectContext);

  useEffect(() => {
    if (type && firstname && lastname && street && houseNo && postcode && location && degree !== null && phone) {
      setIsDisable(false);
    } else {
      setIsDisable(true);
    }
  }, [type, firstname, lastname, street, houseNo, postcode, location, degree, phone])

  useEffect(() => {
    if(ref) {
      setReferral(ref);
    }
  }, [ref])

  useEffect(() => {
    if (!completedFirstStep) {
      navigation("/order/select");
    }
  }, [completedFirstStep])

  const handleNext = () => {
    if (!isValidPhoneNumber(phone)) {
      setPhoneError('Invalid phone number');
      return;
    }
    // if (validateEmail(email)) {
    //   setEmailError(validateEmail(email));
    //   return;
    // }
    setEmailError(null);
    navigation('/order/signature')
  }

  return (
    <div className='bg-[#f4f6fa] w-[100%] pb-[100px] pt-[50px]'>
      <div className='max-w-[1250px] flex flex-col justify-center items-center -lg:items-center space-x-0 space-y-8 mx-auto px-12 -sm:px-4'>
        <div className='flex flex-col justify-center items-start space-y-8 bg-white w-full p-8 rounded-lg text-left'>
          <p className='font-bold'>Daten zur pflegebedürftigen Person</p>
          <div className='flex flex-col justify-start items-start space-y-2'>
            <p className='text-[12px]'>Anrede *</p>
            <div className='flex flex-row justify-start items-center space-x-8'>
              <label>
                <input type="checkbox" checked={type === 'woman' ? true : false} onChange={() => { setType('woman') }} />
                <span>Frau</span>
              </label>
              <label>
                <input type="checkbox" checked={type === 'man' ? true : false} onChange={() => { setType('man') }} />
                <span>Herr</span>
              </label>
            </div>
          </div>

          <div className='w-full flex flex-row justify-center items-center space-x-8'>
            <div className='flex flex-col justify-start items-start space-y-2 w-full'>
              <p className='text-[12px]'>Vorname *</p>
              <input
                type='text'
                name='firstname'
                value={firstname || ""}
                className='w-full border border-gray-300 rounded-lg px-4 py-1 text-[12px] h-[40px]'
                onChange={(e) => {
                  setFirstname(e.target.value);
                }}
              />
            </div>
            <div className='flex flex-col justify-start items-start space-y-2 w-full'>
              <p className='text-[12px]'>Nachname *</p>
              <input
                type='text'
                name='lastname'
                value={lastname || ""}
                className='w-full border border-gray-300 rounded-lg px-4 py-1 text-[12px] h-[40px]'
                onChange={(e) => {
                  setLastname(e.target.value);
                }}
              />
            </div>
          </div>
          <div className='w-full flex flex-row -md:flex-col justify-center items-center space-x-8 -md:space-x-0 -md:space-y-4'>
            <div className='flex flex-row -sm:flex-col justify-start items-center space-x-8 -sm:space-x-0 -sm:space-y-4 w-[50%] -md:w-full'>
              <div className='flex flex-col justify-start items-start space-y-2 w-[65%] -sm:w-full'>
                <p className='text-[12px]'>Straße *</p>
                <input
                  type='text'
                  name='street'
                  value={street || ""}
                  className='w-full border border-gray-300 rounded-lg px-4 py-1 text-[12px] h-[40px]'
                  onChange={(e) => {
                    setStreet(e.target.value);
                  }}
                />
              </div>
              <div className='flex flex-col justify-start items-start space-y-2 w-[35%] -sm:w-full'>
                <p className='text-[12px]'>Haus Nummer. *</p>
                <input
                  type='text'
                  name='houseNo'
                  value={houseNo || ""}
                  className='w-full border border-gray-300 rounded-lg px-4 py-1 text-[12px] h-[40px]'
                  onChange={(e) => {
                    setHouseNo(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className='flex flex-row -sm:flex-col justify-start items-center space-x-8 -sm:space-x-0 -sm:space-y-4 w-[50%] -md:w-full'>
              <div className='flex flex-col justify-start items-start space-y-2 w-[35%] -sm:w-full'>
                <p className='text-[12px]'>PLZ *</p>
                <input
                  type='number'
                  name='postcode'
                  value={postcode || ""}
                  className='w-full border border-gray-300 rounded-lg px-4 py-1 text-[12px] h-[40px]'
                  onChange={(e) => {
                    setPostcode(e.target.value);
                  }}
                />
              </div>
              <div className='flex flex-col justify-start items-start space-y-2 w-[65%] -sm:w-full'>
                <p className='text-[12px]'>Ort *</p>
                <input
                  type='text'
                  name='location'
                  value={location || ""}
                  className='w-full border border-gray-300 rounded-lg px-4 py-1 text-[12px] h-[40px]'
                  onChange={(e) => {
                    setLocation(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
          <div className='flex flex-col justify-start items-start space-y-2'>
            <p className='text-[12px]'>Bestellrythmus *</p>
            <div className='flex flex-row -sm:flex-col justify-start items-center -sm:items-start space-x-8 -sm:space-x-0 -sm:space-y-4'>
              <label>
                <input type="checkbox" checked={interval == 1 ? true : false} onChange={() => { setInterval(1) }} />
                <span>Monatlich</span>
              </label>
              {/* <label>
                <input type="checkbox" checked={interval == 2 ? true : false} onChange={() => { setInterval(2) }} />
                <span>Alle zwei Monate</span>
              </label> */}
              <label>
                <input type="checkbox" checked={interval == 3 ? true : false} onChange={() => { setInterval(3) }} />
                <span>Alle drei Monate</span>
              </label>
            </div>
          </div>
          <div className='bg-[#eff6ff] rounded-[5px] p-4 w-full space-y-4'>
            <label>
              <input type="checkbox" checked={showAddress} onChange={() => { setShowAddress(!showAddress) }} />
              <span className='text-[#6b7280] font-bold select-none'>Abweichende Lieferadresse</span>
            </label>
            <p className='text-[#6b7280] text-[12px]'>Die Lieferung soll nicht an die oben angegebene Adresse der pflegebedürftigen Person gesendet werden? Dann geben Sie hier eine abweichende Lieferanschrift an.</p>
          </div>
        </div>
        {showAddress &&
          <div className='flex flex-col justify-center items-start space-y-8 bg-white w-full p-8 rounded-lg text-left'>
            <p className='font-bold'>Abweichende Lieferadresse</p>
            <div className='w-full flex flex-col justify-center items-center space-y-4'>
              <div className='w-full grid grid-cols-3 -sm:grid-cols-1 justify-start items-center space-x-8 -sm:space-x-0 -sm:space-y-4 -md:w-full'>
                <div className='flex flex-col justify-start items-start space-y-2 -sm:w-full'>
                  <p className='text-[12px]'>Name/Firma</p>
                  <input
                    type='text'
                    name='company'
                    value={company || ""}
                    className='w-full border border-gray-300 rounded-lg px-4 py-1 text-[12px] h-[40px]'
                    onChange={(e) => {
                      setCompany(e.target.value);
                    }}
                  />
                </div>
                <div className='flex flex-col justify-start items-start space-y-2 -sm:w-full'>
                  <p className='text-[12px]'>Straße</p>
                  <input
                    type='text'
                    name='street2'
                    value={street2 || ""}
                    className='w-full border border-gray-300 rounded-lg px-4 py-1 text-[12px] h-[40px]'
                    onChange={(e) => {
                      setStreet2(e.target.value);
                    }}
                  />
                </div>
                <div className='flex flex-col justify-start items-start space-y-2 -sm:w-full'>
                  <p className='text-[12px]'>Haus Nummer</p>
                  <input
                    type='text'
                    name='houseNo2'
                    value={houseNo2 || ""}
                    className='w-full border border-gray-300 rounded-lg px-4 py-1 text-[12px] h-[40px]'
                    onChange={(e) => {
                      setHouseNo2(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className='w-full flex flex-row -sm:flex-col justify-start items-center space-x-8 -sm:space-x-0 -sm:space-y-4 -md:w-full'>
                <div className='flex flex-col justify-start items-start space-y-2 w-[50%] -sm:w-full'>
                  <p className='text-[12px]'>PLZ</p>
                  <input
                    type='text'
                    name='postcode2'
                    value={postcode2 || ""}
                    className='w-full border border-gray-300 rounded-lg px-4 py-1 text-[12px] h-[40px]'
                    onChange={(e) => {
                      setPostcode2(e.target.value);
                    }}
                  />
                </div>
                <div className='flex flex-col justify-start items-start space-y-2 w-[50%] -sm:w-full'>
                  <p className='text-[12px]'>Ort</p>
                  <input
                    type='text'
                    name='location2'
                    value={location2 || ""}
                    className='w-full border border-gray-300 rounded-lg px-4 py-1 text-[12px] h-[40px]'
                    onChange={(e) => {
                      setLocation2(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        }
        <div className='flex flex-col justify-center items-start space-y-8 bg-white w-full p-8 rounded-lg text-left'>
          <p className='font-bold'>Pflegegrad *</p>
          <div className='grid grid-cols-6 -lg:grid-cols-3 -md:grid-cols-2 gap-6 justify-between items-center w-full'>
            {degrees.map((item, index) => {
              return (
                <div
                  key={index}
                  className={`border rounded-lg text-center px-8 -sm:px-4 py-3 text-[12px] min-w-[140px] -sm:min-w-[120px] cursor-pointer ${degree === item && 'bg-[#6ea13b] text-white'}`}
                  onClick={() => {
                    setDegree(item);
                    if (item === 0) {
                      setDegreeWarning("Wenn Sie keinen Pflegegrad haben ist die Bestellung für Sie kostenpflichtig.");
                    } else {
                      setDegreeWarning(null)
                    }
                  }}
                >
                  {item === 0 ? 'Keine' : `Pflegegrad ${item}`}
                </div>
              )
            })}
          </div>
          {degreeWarning && (
          <p className='text-[12px] text-[#de1009]'>
            {degreeWarning}
          </p>)}
        </div>
        <div className='flex flex-col justify-center items-start space-y-8 bg-white w-full p-8 rounded-lg text-left'>
          <p className='font-bold'>Kontaktdaten für eventuelle Rückfragen</p>
          <div className='w-full flex flex-row -md:flex-col justify-center items-start space-x-8 -md:space-x-0 -md:space-y-4'>
            <div className='flex flex-col justify-start items-start space-y-2 w-full'>
              <p className='text-[12px]'>Ihre Telefonnummer *</p>
              {/* <input
                type='text'
                name='phone'
                value={phone || ""}
                className='w-full border border-gray-300 rounded-lg px-4 py-1 text-[12px] h-[40px]'
                onChange={(e) => {
                  setPhone(e.target.value);
                }}
              /> */}
              <PhoneInput
                defaultCountry="DE"
                countries={["DE", "AT", "CH", "NL"]}
                placeholder="Enter phone number"
                value={phone}
                onChange={setPhone}
                className={`w-full border ${phoneError ? 'border-[#de1009]' : 'border-gray-300'} rounded-lg px-4 py-1 text-[12px] h-[40px]`}
              />
              {phoneError && <p className='text-[#de1009] text-[12px] font-bold'>{phoneError}</p>}
            </div>
            <div className='flex flex-col justify-start items-start space-y-2 w-full'>
              <p className='text-[12px]'>Ihre E-Mail Adresse</p>
              <input
                type='email'
                name='email'
                value={email || ""}
                className={`w-full border ${emailError ? 'border-[#de1009]' : 'border-gray-300'} rounded-lg px-4 py-1 text-[12px] h-[40px]`}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
              {/* {emailError && <p className='text-[#de1009] text-[12px] font-bold'>{emailError}</p>} */}
            </div>
            <div className='flex flex-col justify-start items-start space-y-2 w-full'>
              <p className='text-[12px]'>Vertragspartner Nummer</p>
              <input
                type='number'
                maxLength={3}
                name='referral'
                value={referral || ""}
                className={`w-full border border-gray-300 rounded-lg px-4 py-1 text-[12px] h-[40px]`}
                onChange={(e) => {
                  const value = e.target.value.slice(0, 3);
                  setReferral(value);
                }}
              />
            </div>
          </div>
        </div>
        <div className='w-full flex flex-row justify-end items-center space-x-4'>
          <div
            className='flex flex-row justify-start items-center space-x-2 cursor-pointer'
            onClick={() => {
              navigation("/order/select");
            }}
          >
            <BsArrowLeft />
            <p className='text-[12px] font-bold'>Zurück</p>
          </div>
          <div
            className={`flex flex-row justify-center items-center rounded-[10px] py-2 px-8 ${isDisable ? 'bg-[#d1d5db] cursor-not-allowed' : 'bg-[#f98918] hover:bg-[#ffc04f] hover:text-black'} text-[14px] text-white font-bold cursor-pointer`}
            onClick={() => {
              if (!isDisable) {
                setCompletedSecondStep(true);
                handleNext();
              }
            }}
          >
            Jetzt bestellen
          </div>
        </div>
      </div>
    </div>
  )
}

export default Section;