import desinfection_tissues from "../../assets/desinfection_tissues.jpg";
import einmalhandschuhe from "../../assets/Einmalhandschuhe.jpeg";
import fingerlinge from "../../assets/Fingerlinge.png";
import flaechendesinfektionsmittel from "../../assets/Flaechendesinfektionsmittel.jpg";
import haendedesinfektionsmittel from "../../assets/Haendedesinfektionsmittel.jpg";
import ffp2maske from "../../assets/FFP2 Maske.png";

export const Products = [
  {
    title: "Saugende Bettschutzeinlage / wiederverwendbar",
    stock: "3 Stk.",
    photo: "https://funnel.prosenio.eu/static/pictures/sanubi/kuwv.webp",
    price: 26.16,
    min_stock: 1,
    bundle_qty: 1,
    price_without_tax: 21.98,
    price_with_tax: 26.16,
    min_price: 26.16,
    barcode: "51.40.01.4",
  },
  {
    title: "Einmalhandschuhe",
    stock: "100 Stk.",
    photo: einmalhandschuhe,
    price: 9.52,
    min_stock: 1,
    bundle_qty: 100,
    price_without_tax: 0.08,
    price_with_tax: 0.0952,
    min_price: 9.52,
    barcode: "54.99.01.1001",
  },
  {
    title: "Fingerlinge",
    stock: "100 Stk.",
    photo: fingerlinge,
    price: 5.95,
    min_stock: 1,
    bundle_qty: 100,
    price_without_tax: 0.05,
    price_with_tax: 0.0595,
    min_price: 5.95,
    barcode: "54.99.01.0001",
  },
  {
    title: "Händesdesinfektionsmittel",
    stock: "500 ml",
    photo: haendedesinfektionsmittel,
    price: 7.14,
    min_stock: 100,
    bundle_qty: 500,
    price_without_tax: 1.2,
    price_with_tax: 1.43,
    min_price: 7.14,
    barcode: "54.99.02.0001",
  },
  {
    title: "Flächendesinfektionsmittel",
    stock: "500 ml",
    photo: flaechendesinfektionsmittel,
    price: 6.78,
    min_stock: 100,
    bundle_qty: 500,
    price_without_tax: 1.14,
    price_with_tax: 1.36,
    min_price: 6.78,
    barcode: "54.99.02.0002",
  },
  {
    title: "Bettschutzeinlagen",
    stock: "25 Stk.",
    photo:
      "https://funnel.prosenio.eu/static/pictures/sanubi/bettschutz_eg.webp",
    price: 12.2,
    min_stock: 1,
    bundle_qty: 25,
    price_without_tax: 0.41,
    price_with_tax: 0.49,
    min_price: 12.2,
    barcode: "54.45.01.0001",
  },
  {
    title: "Schutzschürzen / Einmalgebrauch",
    stock: "50 Stk.",
    photo:
      "https://funnel.prosenio.eu/static/pictures/sanubi/schutzschuerzen_kurz.webp",
    price: 7.14,
    min_stock: 1,
    bundle_qty: 50,
    price_without_tax: 0.12,
    price_with_tax: 0.14,
    min_price: 7.14,
    barcode: "54.99.01.3001",
  },
  {
    title: "Schutzschürzen / wiederverwendbar",
    stock: "1 Stk.",
    photo:
      "https://funnel.prosenio.eu/static/pictures/sanubi/schutzschuerzen_wv.webp",
    price: 25.59,
    min_stock: 1,
    bundle_qty: 1,
    price_without_tax: 21.5,
    price_with_tax: 25.59,
    min_price: 25.59,
    barcode: "54.99.01.3002",
  },
  {
    title: "Mundschutz",
    stock: "50 Stk.",
    photo:
      "https://funnel.prosenio.eu/static/pictures/sanubi/mundschutz_eg.webp",
    price: 8.33,
    min_stock: 1,
    bundle_qty: 50,
    price_without_tax: 0.14,
    price_with_tax: 0.1666,
    min_price: 8.33,
    barcode: "54.99.01.2001",
  },
  {
    title: "FFP-2 Masken",
    stock: "10 Stk.",
    photo: ffp2maske,
    price: 7.73,
    min_stock: 1,
    bundle_qty: 10,
    price_without_tax: 0.65,
    price_with_tax: 0.77,
    min_price: 7.73,
    barcode: "54.99.01.5001",
  },
  {
    title: "Flächendesinfektionstücher",
    stock: "80 Stk.",
    photo: desinfection_tissues,
    price: 9.52,
    min_stock: 1,
    bundle_qty: 80,
    price_without_tax: 0.1,
    price_with_tax: 0.119,
    min_price: 9.52,
    barcode: "54.99.02.0015",
  },
];

export const ProductsBarcode = [
  "54.99.01.0001",
  "54.99.01.1001",
  "54.99.02.0001",
  "54.99.02.0002",
  "54.45.01.0001",
  "54.99.01.3001",
  "54.99.01.3002",
  "54.99.01.2001",
  "54.99.02.0015",
  "51.40.01.4",
];

export const ProductsTooltips = [
  {
    text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
  },
  {
    text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
  },
  {
    text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
  },
  {
    text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
  },
  {
    text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
  },
  {
    text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
  },
  {
    text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
  },
  {
    text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
  },
  {
    text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
  },
];
