import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { CgSpinner } from "react-icons/cg";
import { required, vusername } from "../../utils";
import { updateUsername } from "../../actions/user";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { isValidPhoneNumber } from "react-phone-number-input";

const General = () => {
  const form = useRef();
  const navigate = useNavigate();
  const checkBtn = useRef();
  const dispatch = useDispatch();
  const { user: currentUser } = useSelector((state) => state.auth);
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [type, setType] = useState(undefined);
  const [street, setStreet] = useState(null);
  const [houseNo, setHouseNo] = useState(null);
  const [postcode, setPostcode] = useState(null);
  const [location, setLocation] = useState(null);
  const [phoneError, setPhoneError] = useState(null);
  const [phone, setPhone] = useState(null);
  const [iban, setIBAN] = useState("");
  const [taxID, setTaxID] = useState("");
  const [company, setCompany] = useState("");

  useEffect(() => {
    if (currentUser) {
      setFirstname(currentUser.firstname);
      setLastname(currentUser.lastname);
      setType(currentUser.type);
      setStreet(currentUser.street);
      setHouseNo(currentUser.houseNo);
      setPostcode(currentUser.postcode);
      setLocation(currentUser.location);
      setPhone(currentUser.phone);
      if (currentUser.role == 'partner') {
        setCompany(currentUser.company);
        setIBAN(currentUser.iban);
        setTaxID(currentUser.banner);
      }
    }
  }, [currentUser]);

  const handleUpdate = (e) => {
    e.preventDefault();
    // if (!isValidPhoneNumber(phone ? phone : "")) {
    //   setPhoneError("Invalid phone number");
    // } else {
    setIsLoading(true);
    form.current.validateAll();
    if (checkBtn.current.context._errors.length === 0) {
      dispatch(
        updateUsername({
          firstname: firstname,
          lastname: lastname,
          type: type,
          street: street,
          houseNo: houseNo,
          postcode: postcode,
          location: location,
          phone: phone,
          company: company,
          iban: iban,
          taxID: taxID
        })
      ).then(() => {
        setIsLoading(false);
        navigate(0);
      });
    } else {
      setIsLoading(false);
    }
    // }
  };

  return (
    <div className="flex flex-col justify-start items-start space-y-8 w-full">
      <span className="text-[21px] font-SenSans">Persönliche Angaben</span>
      {currentUser?.role === "partner" && (
        <div className="flex flex-row justify-start items-center space-x-4">
          <p className="text-[16px]">Vertragspartnernummer: </p>
          <p className="text-[20px] font-bold px-4 rounded-lg bg-black text-white">
            {currentUser.referralCode}
          </p>
        </div>
      )}
      <div className="flex flex-row -sm:flex-col justify-start items-start space-x-16 -sm:space-x-0 -sm:space-y-8 w-full">
        <Form
          onSubmit={handleUpdate}
          ref={form}
          className="w-full flex flex-col justify-start itesm-center space-y-4"
        >
          <div className="flex flex-col justify-start items-start space-y-2 w-full">
            <p className="text-[12px]">Anrede *</p>
            <div className="flex flex-row justify-start items-center space-x-8">
              <label>
                <input
                  type="checkbox"
                  checked={type === "woman" ? true : false}
                  onChange={() => {
                    setType("woman");
                  }}
                />
                <span>Frau</span>
              </label>
              <label>
                <input
                  type="checkbox"
                  checked={type === "man" ? true : false}
                  onChange={() => {
                    setType("man");
                  }}
                />
                <span>Herr</span>
              </label>
            </div>
          </div>
          {
            currentUser?.role == 'partner' &&
              <div className="w-full flex flex-row -md:flex-col justify-center items-center space-x-8 -md:space-x-0 -md:space-y-4">
                <div className="flex flex-col justify-start items-start space-y-2 w-full">
                  <p className="text-[12px]">Firma</p>
                  <input
                    type="text"
                    name="company"
                    value={company || ""}
                    className="w-full border border-gray-300 rounded-lg px-4 py-1 text-[12px] h-[40px]"
                    onChange={(e) => {
                      setCompany(e.target.value);
                    }}
                  />
                </div>
                <div className="flex flex-col justify-start items-start space-y-2 w-full">
                  <p className="text-[12px]">IBAN</p>
                  <input
                    type="text"
                    name="iban"
                    value={iban || ""}
                    className="w-full border border-gray-300 rounded-lg px-4 py-1 text-[12px] h-[40px]"
                    onChange={(e) => {
                      setIBAN(e.target.value);
                    }}
                  />
                </div>
                <div className="flex flex-col justify-start items-start space-y-2 w-full">
                  <p className="text-[12px]">Steuernummer</p>
                  <input
                    type="text"
                    name="taxID"
                    value={taxID || ""}
                    className="w-full border border-gray-300 rounded-lg px-4 py-1 text-[12px] h-[40px]"
                    onChange={(e) => {
                      setTaxID(e.target.value);
                    }}
                  />
                </div>
              </div>
          }
          <div className="w-full flex flex-row justify-center items-center space-x-8">
            <div className="flex flex-col justify-start items-start space-y-2 w-full">
              <p className="text-[12px]">Vorname *</p>
              <input
                type="text"
                name="firstname"
                value={firstname || ""}
                className="w-full border border-gray-300 rounded-lg px-4 py-1 text-[12px] h-[40px]"
                onChange={(e) => {
                  setFirstname(e.target.value);
                }}
              />
            </div>
            <div className="flex flex-col justify-start items-start space-y-2 w-full">
              <p className="text-[12px]">Nachname *</p>
              <input
                type="text"
                name="lastname"
                value={lastname || ""}
                className="w-full border border-gray-300 rounded-lg px-4 py-1 text-[12px] h-[40px]"
                onChange={(e) => {
                  setLastname(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="w-full flex flex-row -md:flex-col justify-center items-center space-x-8 -md:space-x-0 -md:space-y-4">
            <div className="flex flex-row -sm:flex-col justify-start items-center space-x-8 -sm:space-x-0 -sm:space-y-4 w-[50%] -md:w-full">
              <div className="flex flex-col justify-start items-start space-y-2 w-[65%] -sm:w-full">
                <p className="text-[12px]">Straße *</p>
                <input
                  type="text"
                  name="street"
                  value={street || ""}
                  className="w-full border border-gray-300 rounded-lg px-4 py-1 text-[12px] h-[40px]"
                  onChange={(e) => {
                    setStreet(e.target.value);
                  }}
                />
              </div>
              <div className="flex flex-col justify-start items-start space-y-2 w-[35%] -sm:w-full">
                <p className="text-[12px]">Haus Nummer. *</p>
                <input
                  type="text"
                  name="houseNo"
                  value={houseNo || ""}
                  className="w-full border border-gray-300 rounded-lg px-4 py-1 text-[12px] h-[40px]"
                  onChange={(e) => {
                    setHouseNo(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="flex flex-row -sm:flex-col justify-start items-center space-x-8 -sm:space-x-0 -sm:space-y-4 w-[50%] -md:w-full">
              <div className="flex flex-col justify-start items-start space-y-2 w-[35%] -sm:w-full">
                <p className="text-[12px]">PLZ *</p>
                <input
                  type="number"
                  name="postcode"
                  value={postcode || ""}
                  className="w-full border border-gray-300 rounded-lg px-4 py-1 text-[12px] h-[40px]"
                  onChange={(e) => {
                    setPostcode(e.target.value);
                  }}
                />
              </div>
              <div className="flex flex-col justify-start items-start space-y-2 w-[65%] -sm:w-full">
                <p className="text-[12px]">Ort *</p>
                <input
                  type="text"
                  name="location"
                  value={location || ""}
                  className="w-full border border-gray-300 rounded-lg px-4 py-1 text-[12px] h-[40px]"
                  onChange={(e) => {
                    setLocation(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="w-full flex flex-row -md:flex-col justify-center items-start space-x-8 -md:space-x-0 -md:space-y-4">
            <div className="flex flex-col justify-start items-start space-y-2 w-full">
              <p className="text-[12px]">Ihre Telefonnummer *</p>
              <PhoneInput
                defaultCountry="DE"
                placeholder="Enter phone number"
                value={phone}
                onChange={setPhone}
                className={`w-full border ${
                  phoneError ? "border-[#de1009]" : "border-gray-300"
                } rounded-lg px-4 py-1 text-[12px] h-[40px]`}
              />
              {phoneError && (
                <p className="text-[#de1009] text-[12px] font-bold">
                  {phoneError}
                </p>
              )}
            </div>
          </div>
          <div className="form-group">
            <button className="!w-[170px] !h-[46px] font-RNSSanzBold !text-[18px]">
              {isLoading ? (
                <CgSpinner className={`text-white text-[30px] animate-spin`} />
              ) : (
                <span className="text-white">Änderungen speichern</span>
              )}
            </button>
          </div>
          <CheckButton style={{ display: "none" }} ref={checkBtn} />
        </Form>
        {/* <UploadAvatar /> */}
      </div>
    </div>
  );
};

export default General;
