import React from "react";
import CloseSVG from '../../assets/svgs/close.svg';
import DeliverSVG from '../../assets/svgs/deliver.svg';

const OrderConfirmModal = ({handleCancel}) => {
  return (
    <div
      className={`fixed h-full w-full top-0 left-0 bg-[rgba(138,148,153,0.7)] bg-opacity-80 flex items-center justify-center z-30 overflow-y-scroll overflow-x-hidden detail-modal`}
    >
      <div className="relative mx-auto rounded-lg bg-white my-[50px] -md:w-[96%] md:w-[600px]">
        <div className="w-full p-4 bg-white rounded-tl-lg rounded-tr-lg flex flex-row justify-between items-center">
          <div className="text-[20px] font-RNSSanzBold">
          </div>
          <div
            className="flex flex-row justify-center items-center bg-[#0000000d] w-[30px] h-[30px] rounded-full cursor-pointer"
            onClick={handleCancel}
          >
            <img src={CloseSVG} className="w-[20px] h-[20px]" />
          </div>
        </div>
        <div className="flex flex-col justify-center items-center text-center font-RNSSanzBold pb-12 px-8 space-y-8">
          <p className='text-[30px] -sm:text-[24px] text-[#6ea13b] font-bold'>Vielen Dank für Ihre Bestellung!</p>
          <div className='text-[16px] w-full'>
            <strong className='pr-1'>Vielen Dank für Ihre Bestellung!</strong>
            <p>Der Versand erfolgt sofort nach Genehmigung durch Ihre Pflegekasse.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderConfirmModal;
