import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { CgSpinner } from "react-icons/cg";
import { required, vpassword, vpasswordConfirm } from "../../utils";
import { updatePassword } from "../../actions/user";

const Password = () => {
  const form = useRef();
  const checkBtn = useRef();
  const dispatch = useDispatch();
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const onChangeCurrentPassword = (e) => {
    const currentPassword = e.target.value;
    setCurrentPassword(currentPassword);
  };

  const onChangeNewPassword = (e) => {
    const newPassword = e.target.value;
    setNewPassword(newPassword);
  };

  const onChangeConfirmPassword = (e) => {
    const confirmPassword = e.target.value;
    setConfirmPassword(confirmPassword);
  };

  const handleUpdatePassword = (e) => {
    e.preventDefault();
    setIsLoading(true);
    form.current.validateAll();
    if (checkBtn.current.context._errors.length === 0) {
      dispatch(
        updatePassword({
          currentPassword: currentPassword,
          newPassword: newPassword,
        })
      )
        .then(() => {
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex flex-col justify-start items-start space-y-8">
      <span className="text-[21px] font-SenSans">Persönliches Passwort</span>
      <div className="flex flex-row -sm:flex-col justify-start items-start space-x-16 -sm:space-x-0 -sm:space-y-8">
        <Form
          onSubmit={handleUpdatePassword}
          ref={form}
          className="flex flex-col justify-start itesm-center space-y-4"
        >
          <div className="flex flex-col justify-start items-start space-y-4">
            <div className="form-group">
              <label
                htmlFor="currentPassword"
                className="text-[12px] font-RNSSanzMedium pb-1 text-[#8A9499]"
              >
                Aktuelles Passwort
              </label>
              <Input
                type="password"
                className="form-control !w-[250px] !bg-white"
                name="currentPassword"
                value={currentPassword}
                onChange={onChangeCurrentPassword}
                validations={[required, vpassword]}
              />
            </div>
            <div className="form-group">
              <label
                htmlFor="password"
                className="text-[12px] font-RNSSanzMedium pb-1 text-[#8A9499]"
              >
                Neues Passwort
              </label>
              <Input
                type="password"
                className="form-control !w-[250px] !bg-white"
                name="password"
                value={newPassword}
                onChange={onChangeNewPassword}
                validations={[required, vpassword]}
              />
            </div>
            <div className="form-group">
              <label
                htmlFor="confirmPassword"
                className="text-[12px] font-RNSSanzMedium pb-1 text-[#8A9499]"
              >
                Neues Passwort bestätigen
              </label>
              <Input
                type="password"
                className="form-control !w-[250px] !bg-white"
                name="confirmPassword"
                value={confirmPassword}
                onChange={onChangeConfirmPassword}
                validations={[required, vpasswordConfirm]}
              />
            </div>
          </div>
          <div className="form-group">
            <button className="!w-[170px] !h-[46px] font-RNSSanzBold !text-[18px]">
              {isLoading ? (
                <CgSpinner className={`text-white text-[30px] animate-spin`} />
              ) : (
                <span className="text-white">Änderungen speichern</span>
              )}
            </button>
          </div>
          <CheckButton style={{ display: "none" }} ref={checkBtn} />
        </Form>
      </div>
    </div>
  );
};

export default Password;
