import { isEmail } from "validator";
import i18n from "../i18n";

export const required = (value) => {
  if (!value) {
    return (
      <div className="text-[red] font-RNSSanzMedium text-[12px] pl-2 tracking-[0.5px]" role="alert">
        {i18n.t('auth.validation.require')}
      </div>
    );
  }
};

export const validEmail = (value) => {
  if (!isEmail(value)) {
    return (
      <div className="text-[red] font-RNSSanzMedium text-[12px] pl-2 tracking-[0.5px]" role="alert">
        {i18n.t('auth.validation.validemail')}
      </div>
    );
  }
};

export const vusername = (value) => {
  if (value.length < 3 || value.length > 20) {
    return (
      <div className="text-[red] font-RNSSanzMedium text-[12px] pl-2 tracking-[0.5px] !w-[165px] -sm:!w-[250px]" role="alert">
        {i18n.t('auth.validation.validusername')}
      </div>
    );
  }
};

export const vpassword = (value) => {
  if (value.length < 6 || value.length > 40) {
    return (
      <div className="text-[red] font-RNSSanzMedium text-[12px] pl-2 tracking-[0.5px]" role="alert">
        {i18n.t('auth.validation.validpassword')}
      </div>
    );
  }
};

export const vpasswordConfirm = (value, props, components) => {
  if (value !== components['password'][0].value) {
    return (
      <div className="text-[red] font-RNSSanzMedium text-[12px] pl-2 tracking-[0.5px]" role="alert">
        {i18n.t('auth.validation.validpasswordconfirm')}
      </div>
    );
  }
}