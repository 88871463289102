export const Insurances = [
  {
    name: 'AOK Baden-Württemberg',
    address: 'Presselstraße 19 70191 Stuttgart',
    ik: '108018007'
  },
  {
    name: 'AOK Bayern',
    address: 'Carl-Wery-Str. 28 81739 München',
    ik: '108310400'
  },
  {
    name: 'AOK Bremen / Bremerhaven',
    address: 'Bürgermeister-Smidt-Str. 95 28195 Bremen',
    ik: '103119199'
  },
  {
    name: 'AOK Hessen',
    address: 'Basler Str. 2 61352 Bad Homburg',
    ik: '105313145'
  },
  {
    name: 'AOK Niedersachsen**',
    address: 'Hildesheimer Str. 273 30519 Hannover',
    ik: '102114819'
  },
  {
    name: 'AOK Nordost (AOK Berlin)',
    address: 'Wilhelmstr. 1 10963 Berlin',
    ik: '109519005'
  },
  {
    name: 'AOK Nordost (AOK Brandenburg)',
    address: 'Potsdamer Straße 20 14513 Teltow',
    ik: '100696012'
  },
  {
    name: 'AOK Nordost (AOK Mecklenburg-Vorpommern)',
    address: 'Am Grünen Tal 50 19063 Schwerin',
    ik: '100395611'
  },
  {
    name: 'AOK NordWest** (AOK Schleswig-Holstein)',
    address: 'Edisonstraße 70 24145 Kiel',
    ik: '101317004'
  },
  {
    name: 'AOK NordWest** (AOK Westfalen-Lippe)',
    address: 'Kopenhagener Str. 44263 Dortmund',
    ik: '103411401'
  },
  {
    name: 'AOK Rheinland/Hamburg (AOK Hamburg)',
    address: 'Pappelallee 22 – 26 22089 Hamburg',
    ik: '101519213'
  },
  {
    name: 'AOK Rheinland/Hamburg (AOK Rheinland)',
    address: 'Kasernenstraße 61 40213 Düsseldorf',
    ik: '104212505'
  },
  {
    name: 'AOK PLUS (Sachsen)**',
    address: 'Sternplatz 7 01067 Dresden',
    ik: '107299005'
  },
  {
    name: 'AOK PLUS (Thüringen)**',
    address: 'Augustinerstr. 38 99084 Erfurt',
    ik: '105998018'
  },
  {
    name: 'AOK Sachsen-Anhalt',
    address: 'Lüneburger Str. 2 39106 Magdeburg',
    ik: '101097008'
  },
  {
    name: 'Audi BKK',
    address: 'Ettinger Str. 70 85057 Ingolstadt',
    ik: '108534160, 108837428 (Ost), 109132678, 189132678'
  },
  {
    name: 'BKK24',
    address: 'Sülbecker Brand 1 31683 Obernkirchen',
    ik: '102122660, 102732316 (Ost), 102192459, 108029306, 108031297'
  },
  {
    name: 'BKK Deutsche Bank AG',
    address: 'Königsallee 60c 40212 Düsseldorf',
    ik: '104224634'
  },
  {
    name: 'Bertelsmann BKK',
    address: 'Carl-Miele-Str. 214 33335 Gütersloh',
    ik: '103725342'
  },
  {
    name: 'BKK EVM',
    address: 'Schützenstr. 80-82 56068 Koblenz',
    ik: '106331593, 186331593'
  },
  {
    name: 'BKK Groz-Beckert',
    address: 'Parkweg 2 72458 Albstadt',
    ik: '107835071'
  },
  {
    name: 'BKK Merck',
    address: 'Frankfurter Str. 133 64293 Darmstadt',
    ik: '105230076'
  },
  {
    name: 'BKK SBH',
    address: 'Löhrstr. 45 78647, Trossingen',
    ik: '107531187'
  },
  {
    name: 'BKK Werra-Meissner',
    address: 'Sudetenlandstr. 2a 37269 Eschwege',
    ik: '105530126, 185530126'
  },
  {
    name: 'BMW BKK',
    address: 'Mengkofener Str. 6 84130 Dingolfing',
    ik: '10657213'
  },
  {
    name: 'Mercedes-Benz BKK (ehemals Daimler BKK)',
    address: 'Mercedesstr. 1 28309 Bremen',
    ik: '108030775, 108038078 (Ost)'
  },
  {
    name: 'HEK',
    address: 'Wandsbeker Zollstraße 86 – 90 22041 Hamburg',
    ik: '101570104'
  },
  {
    name: 'IKK gesund plus**',
    address: 'Umfassungsstraße 85 39124 Magdeburg',
    ik: '101202961'
  },
  {
    name: 'IKK-Die Innovationskasse**',
    address: 'Augustastraße 3-5 24937 Flensburg',
    ik: '101300129, 100202549 (Ost), 181300129 (Pflegekasse)'
  },
  {
    name: 'Kaufmännische Krankenkasse KKH**',
    address: 'Karl-Wiechert-Allee 61 30625 Hannover',
    ik: '102171012'
  },
  {
    name: 'Knappschaft',
    address: 'Pieperstrasse 14-28 44789, Bochum',
    ik: '109905003'
  },
  {
    name: 'Salus BKK',
    address: 'Siemensstraße 5 a 63263 Neu-Isenburg',
    ik: '106330000, 105330168, 105321269 (Ost)'
  },
  {
    name: 'Techniker Krankenkasse**',
    address: 'Postfach 210650 47028 Duisburg',
    ik: 'siehe Anhang'
  },
  {
    name: 'AOK Rheinland-Pfalz/Saarland**',
    address: 'Virchowstraße 30 67304 Eisenberg',
    ik: '107310373 109319309'
  },
  {
    name: 'BAHN-BKK',
    address: 'Franklinstr. 54 60486 Frankfurt',
    ik: '109938503 109920569 (Ost)'
  },
  {
    name: 'BKK Akzo-Nobel',
    address: 'Glanzstoffstr. 63785 Obernburg',
    ik: '108833355 188833355'
  },
  {
    name: 'BKK BPW Bergische Achsen KG',
    address: 'Ohler Berg 1 51674 Wiehl',
    ik: '104626903 184626903'
  },
  {
    name: 'BKK Diakonie',
    address: 'Königsweg 8 33617 Bielefeld',
    ik: '103724294 103729701 (Ost) 183724294 183729701'
  },
  {
    name: 'BKK DürkoppAdler',
    address: 'Potsdamer Str. 190 33719 Bielefeld',
    ik: '103724249'
  },
  {
    name: 'BKK Faber-Castell & Partner',
    address: 'Bahnhofstraße 45 94209 Regen',
    ik: 'siehe Anhang'
  },
  {
    name: 'BKK firmus',
    address: 'Gottlieb-Daimler-Str. 11 28237 Bremen',
    ik: '103121137 102529638 (Ost)'
  },
  {
    name: 'BKK Freudenberg',
    address: 'Höhnerweg 2 469465 Weinheim',
    ik: '107036370 187036370'
  },
  {
    name: 'BKK Gildemeister Seidensticker',
    address: 'Winterstraße 49 33649 Bielefeld',
    ik: '103791489 183791489 103724272 183724272'
  },
  {
    name: 'BKK KBA',
    address: 'Friedrich-Koenig-Str. 4 97080 Würzburg',
    ik: '108833674 108831386 (Ost)'
  },
  {
    name: 'BKK Krones',
    address: 'Bayerwaldstraße 2L 93073 Neutraubling',
    ik: '108934142 188934142'
  },
  {
    name: 'BKK Linde',
    address: 'Abraham-Lincoln-Str. 18 65189 Wiesbaden',
    ik: '105830517 105821242 (Ost)'
  },
  {
    name: 'BKK MAHLE',
    address: 'Pragstraße 26-46 70376 Stuttgart',
    ik: '108036145 188036145'
  },
  {
    name: 'BKK melitta hmr',
    address: 'Marienstr. 120 32425 Minden',
    ik: '103726081 183726081'
  },
  {
    name: 'BKK Miele',
    address: 'Carl-Miele-Straße 29 33332 Gütersloh',
    ik: '103725364 183725364'
  },
  {
    name: 'BKK Pfaff',
    address: 'Pirmasenser Str. 132 67655 Kaiserslautern',
    ik: '106431572 186431572'
  },
  {
    name: 'BKK Pfalz',
    address: 'Lichtenberger Straße 16 67059 Ludwigshafen',
    ik: '106431652 106437453 (Ost)'
  },
  {
    name: 'BKK ProVita',
    address: 'Münchner Weg 5 85232 Bergkirchen',
    ik: '108591499 188591499 105830539 185830539 108591503'
  },
  {
    name: 'BKK Public',
    address: 'Thiestr. 15 38226 Salzgitter',
    ik: '101931440 181931440'
  },
  {
    name: 'BKK Salzgitter',
    address: 'Thiestr. 15 38226 Salzgitter',
    ik: '101922757 181922757'
  },
  {
    name: 'BKK Scheufelen',
    address: 'Schöllkopfstraße 120 73230 Kirchheim/Teck',
    ik: '108035576'
  },
  {
    name: 'BKK Technoform',
    address: 'Weender Landstr. 94-108 37075 Göttingen',
    ik: '102031410 182031410'
  },
  {
    name: 'BKK Textilgruppe Hof',
    address: 'Fabrikzeile 21 95028 Hof',
    ik: '108632900'
  },
  {
    name: 'BKK TUI',
    address: 'Karl-Wiechert-Allee 23 30625 Hannover',
    ik: '102137985 182137985'
  },
  {
    name: 'BKK Verbundplus',
    address: 'Bismarckring 64 88400 Biberach',
    ik: '107832012 107832023'
  },
  {
    name: 'BKK Würth',
    address: 'Gartenstr. 11 74653 Künzelsau',
    ik: '108036577 108023140'
  },
  {
    name: 'Bosch BKK',
    address: 'Kruppstr. 19 70469 Stuttgart',
    ik: '108023388 108036123 188036123'
  },
  {
    name: 'DAK-Gesundheit',
    address: 'Nagelsweg 27 – 31 20097 Hamburg',
    ik: 'siehe Anhang'
  },
  {
    name: 'energie-BKK',
    address: 'Lange Laube 6 30159 Hannover',
    ik: 'siehe Anhang'
  },
  {
    name: 'Ernst & Young BKK',
    address: 'Rotenburger Str. 16 34212 Melsungen',
    ik: '105732324'
  },
  {
    name: 'hkk',
    address: 'Martinistr. 26 28195 Bremen',
    ik: '103170002'
  },
  {
    name: 'IKK Brandenburg und Berlin',
    address: 'Ziolkowskistr. 6 14480 Potsdam',
    ik: '100602360 109500297'
  },
  {
    name: 'IKK classic**',
    address: 'Schlachthofstr. 3 71636 Ludwigsburg',
    ik: 'siehe Anhang'
  },
  {
    name: 'IKK Südwest',
    address: 'Berliner Promenade 1 66111 Saarbrücken',
    ik: '109303301'
  },
  {
    name: 'Sozialversicherung für Landwirtschaft, Forsten und Gartenbau (LKK)',
    address: 'Luisenstr. 12 34119 Kassel',
    ik: 'IK siehe Anhang'
  },
  {
    name: 'LVM Krankenversicherungs-AG',
    address: 'Kolde-Ring 21 48151 Münster',
    ik: '168141096'
  },
  {
    name: 'mhplus Betriebskrankenkasse',
    address: 'Franckstraße 8 71636 Ludwigsburg',
    ik: '108035612 107835743 187835743'
  },
  {
    name: 'PBHH Landesamt für Soziales, Jugend und Familie',
    address: 'Am Waterlooplatz 11 30169 Hannover',
    ik: 'IK siehe Anhang'
  },
  {
    name: 'pronova BKK',
    address: 'Brunckstraße 47 67063 Ludwigshafen',
    ik: '106492393 102522653 (Ost)'
  },
  {
    name: 'R+V BKK',
    address: 'Kreuzberger Ring 21 65205 Wiesbaden',
    ik: '105823040 105823051 (Ost) 185823040'
  },
  {
    name: 'SECURVITA Krankenkasse',
    address: 'Lübeckertordamm 1-3 20099 Hamburg',
    ik: '101320032 101320942 (Ost)'
  },
  {
    name: 'WMF BKK',
    address: 'Eberhardstraße 73312 Geislingen',
    ik: '108036441 188036441'
  },
  {
    name: 'BARMER **',
    address: 'Lichtscheider Str. 89-95 42285 Wuppertal',
    ik: '104940005'
  },
  {
    name: 'BIG direkt gesund',
    address: 'Rheinische Strasse 1 44137 Dortmund',
    ik: '103501080 104229606 (DAS West) 104229582 (DAS Ost) 104227169 (BAGEL)'
  },
  {
    name: 'BKK B. Braun Aesculap',
    address: 'Grüne Straße 1 34212 Melsungen',
    ik: '105530444 105530422 107536171 105530442 185530422'
  },
  {
    name: 'BKK Die Bergische Krankenkasse',
    address: 'Heresbachstr. 29 42719 Solingen',
    ik: '104926702'
  },
  {
    name: 'BKK EUREGIO',
    address: 'Boos-Fremery-Str. 62 52525 Heinsberg',
    ik: '104125509'
  },
  {
    name: 'BKK EWE',
    address: 'Staulinie 16-17 26122 Oldenburg',
    ik: '102429648'
  },
  {
    name: 'BKK exklusiv',
    address: 'Zum Blauen See 7 31275 Lehrte',
    ik: '102122557'
  },
  {
    name: 'BKK Herkules',
    address: 'Fünffensterstr. 5 34117 Kassel',
    ik: '109938503'
  },
  {
    name: 'BKK Mobil Oil',
    address: 'Burggrafstr. 1 29221 Celle',
    ik: '101520078 102192471 (Ost)'
  },
  {
    name: 'BKK MTU Friedrichshafen',
    address: 'Hochstraße 40 88045 Friedrichshafen',
    ik: '107835333'
  },
  {
    name: 'BKK PwC',
    address: 'Rotenburger Str. 15 34212 Melsungen',
    ik: '105723301 185723301 105792190 (Ost) 185792190'
  },
  {
    name: 'BKK VBU',
    address: 'Lindenstraße 67 10969 Berlin',
    ik: '109723913 109737010 (Ost) 105330191 100820488 180820488'
  },
  {
    name: 'BKK VDN',
    address: 'Rosenweg 15 58239 Schwerte',
    ik: '103526615 103532065 (Ost)'
  },
  {
    name: 'BKK Voralb HELLER*LEUZE*TRAUB',
    address: 'Neuffener Str. 54 72622 Nürtingen',
    ik: '108031424'
  },
  {
    name: 'BKK Wirtschaft & Finanzen',
    address: 'Bahnhofstraße 19 34212 Melsungen',
    ik: '105734543 105792123 (Ost)'
  },
  {
    name: 'BKK ZF & Partner',
    address: 'Charlottenstr. 2 88045 Friedrichshafen',
    ik: '107829563 107829574 (Ost) 107829563 (Pflegekasse West) 107829574 (Pflegekasse West)'
  },
  {
    name: 'Debeka BKK',
    address: 'Im Metternicher Feld 40 56072 Koblenz',
    ik: '106329225 106329236'
  },
  {
    name: 'Die Continentale BKK',
    address: 'Ruhrallee 9 44139 Dortmund',
    ik: '103523440 183523440 105530364 185530364'
  },
  {
    name: 'Heimat Krankenkasse',
    address: 'Herforder Straße 23 33602 Bielefeld',
    ik: '103724238 103723896 (Ost)'
  },
  {
    name: 'NOVITAS BKK',
    address: 'Kurfürstenstr. 58 45138 Essen',
    ik: '104491707 104491718 (Ost)'
  },
  {
    name: 'SBK (Siemens BKK)**',
    address: 'Heimeranstraße 31 80339 München',
    ik: '108433248'
  },
  {
    name: 'SKD BKK',
    address: 'Gunnar-Wester-Str. 12 97421 Schweinfurt',
    ik: '108833505 108823455 188833505 188823455'
  },
  {
    name: 'Südzucker BKK',
    address: 'Philosophenplatz 1 68165 Mannheim',
    ik: '106936311 106928388 (Ost)'
  },
  {
    name: 'Viactiv Krankenkasse',
    address: 'Universitätsstraße 43 44789 Bochum',
    ik: '104526376 104529255 184526376 103525909 (Ost)'
  },
  {
    name: 'vivida BKK',
    address: 'Martinistr. 26 28195 Bremen',
    ik: '107536262'
  },
  // {
  //   name: 'Sonstiges/Privat versichert',
  //   address: '',
  //   ik: ''
  // },
  {
    name: 'Beihilfe Stelle 50%',
    address: '',
    ik: '',
    special: true
  },
  {
    name: 'Beihilfe Stelle 70%',
    address: '',
    ik: '',
    special: true
  },
]