import React from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const RequireAdminLayout = ({ children }) => {
  const { user: currentUser } = useSelector(state => state.auth);

  if (!currentUser) {
    return <Navigate to="/login" />
  }

  if (currentUser.role === 'user') {
    return <Navigate to="/order/select" />
  }

  return (
    <>
      {children}
    </>
  )
}

export default RequireAdminLayout;