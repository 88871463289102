import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FaFacebookF, FaInstagram } from 'react-icons/fa';


const Footer = () => {
  return (
    <div className='bg-[#63b744] w-[100%] py-12 font-Mulish'>
      <div className='max-w-[1000px] mx-auto flex flex-col space-y-4 justify-center items-center px-8'>
        <div className='flex flex-row justify-center items-center space-x-4 pb-8'>
          <a href='https://facebook.com/pflegeimkarton' target='_blank'>
            <FaFacebookF className='text-[#3b5998] text-[30px]' />
          </a>
          <a href='https://instagram.com/pflegeimkarton' target='_blank'>
            <FaInstagram className='text-[#E1306C] text-[30px]' />
          </a>
        </div>
        <div className='flex flex-row justify-center items-center space-x-6 -md:flex-col -md:space-x-0 -md:space-y-4'>
          <a href='https://pflegeimkarton.de/allgemeine-geschaeftsbedingungen-agb/' className='text-[#3b5998] hover:text-white text-[13px] font-bold'>
            Allgemeine Geschäftsbedingungen (AGB)
          </a>
          <a href='https://pflegeimkarton.de/datenschutzbestimmungen/' className='text-[#3b5998] hover:text-white text-[13px] font-bold'>
            Datenschutzbestimmungen
          </a>
          <a href='https://pflegeimkarton.de/widerrufsbelehrung/' className='text-[#3b5998] hover:text-white text-[13px] font-bold'>
            Widerrufsbelehrung
          </a>
          <a href='https://pflegeimkarton.de/impressum/' className='text-[#3b5998] hover:text-white text-[13px] font-bold'>
            Impressum
          </a>
        </div>
        <div className='text-[14px] text-white text-center font-bold'>
          <div>
            <p>© Copyright 2023 - Pflege im Karton</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer;