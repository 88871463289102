import React, { useState, useRef } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { required, validEmail } from "../../utils";
import { CgSpinner } from "react-icons/cg";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { login, resend } from "../../actions/auth";
import { SET_MESSAGE } from "../../actions/types";
import Header from "../components/Header";
import Footer from "../components/Footer";

const Login = () => {
  const { t } = useTranslation();
  const form = useRef(null);
  const checkBtn = useRef(null);
  const navigation = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const { isLoggedIn, verify } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const onChangeEmail = (e) => {
    const email = e.target.value;
    setEmail(email);
  };

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };

  const handleLogin = (e) => {
    e.preventDefault();
    setLoading(true);
    form.current.validateAll();
    if (checkBtn.current.context._errors.length === 0) {
      dispatch(login(email, password))
        .then((payload) => {
          if (payload.role === 'user') {
            navigation("/order/select");
          } else {
            navigation("/admin/order-list")
          }
        })
        .catch((errorMsg) => {
          if (errorMsg) {
            console.log(errorMsg);
          }
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  };

  const handleResendEmail = () => {
    if (verify) {
      dispatch(resend(verify))
        .then((successMsg) => {
          dispatch({
            type: SET_MESSAGE,
            payload: {
              success: true,
              message: successMsg,
            },
          });
        })
        .catch((errorMsg) => {
          dispatch({
            type: SET_MESSAGE,
            payload: {
              success: false,
              message: errorMsg,
            },
          });
        });
    }
  };
  

  if (isLoggedIn) {
    return <Navigate to="/" />;
  }

  return (
    <div className="bg-[#f4f6fa]">
      <Header />
      <div className="h-[calc(100vh-231px)] min-h-[900px] flex bg-gray-100">
        <div className="md:w-[600px] -md:w-[95%] m-auto bg-white rounded-lg border border-primaryBorder shadow-default py-10 px-12 -sm:px-8">
          <h1 className="text-2xl font-bold text-primary mt-4 mb-12 text-center text-[#6ea13b]">
            {t("auth.login.text")}
          </h1>
          <Form
            onSubmit={handleLogin}
            ref={form}
            className="flex flex-col justify-start itesm-center space-y-6 mt-[20px]"
          >
            <div className="form-group">
              <Input
                type="text"
                className="form-control !bg-white"
                name="email"
                value={email}
                onChange={onChangeEmail}
                validations={[required, validEmail]}
                placeholder={t("auth.login.placeholder.email")}
              />
            </div>
            <div className="form-group">
              <Input
                type="password"
                className="form-control !bg-white"
                name="password"
                value={password}
                onChange={onChangePassword}
                validations={[required]}
                placeholder={t("auth.login.placeholder.password")}
              />
            </div>
            <div
              className="font-RNSSanzBold text-[12px] select-none text-[#6ea13b] underline flex justify-end w-full cursor-pointer px-4"
              onClick={() => navigation("/password_reset")}
            >
              Passwort vergessen?
            </div>
            <div className="form-group flex flex-col justify-center items-center space-y-4">
              <button disabled={loading}>
                {loading ? (
                  <CgSpinner
                    className={`text-white text-[30px] animate-spin`}
                  />
                ) : (
                  <span className="uppercase text-white">
                    {t("auth.login.title")}
                  </span>
                )}
              </button>
              {verify.email && (
                <div
                  className="text-[#6ea13b] font-RNSSanzBold underline cursor-pointer text-[12px] px-4"
                  onClick={() => handleResendEmail()}
                >
                  {t("auth.login.resend")}
                </div>
              )}
            </div>
            <CheckButton style={{ display: "none" }} ref={checkBtn} />
          </Form>
          <div className="flex flex-row justify-center items-center space-x-2 mt-4 -sm:space-x-0 -sm:flex-col -sm:space-y-2">
            <p className="text-[12px]">Sie haben noch kein Konto?</p>
            <p
              className="text-[12px] text-[#6ea13b] font-bold cursor-pointer"
              onClick={() => navigation("/register")}
            >
              Jetzt anmelden
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Login;
