import React, { useState, useEffect, useMemo } from "react";
import moment from "moment";
import { CgSpinner } from "react-icons/cg";
import { FaUserEdit, FaUserTimes, FaUserPlus } from "react-icons/fa";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import UserModal from "./UserModal";
import ConfirmModal from "../orders/ConfirmModal";
import { useDispatch, useSelector } from "react-redux";
import { deleteUser, getUsers } from "../../../actions/admin";
import DataTable, { Media } from "react-data-table-component";
import BlankAvatarSVG from "../../../assets/svgs/blank.svg";

const CustomLoader = () => {
  return (
    <div className="w-full flex flex-col justify-center items-center space-y-8 mt-16">
      <CgSpinner className={`text-[#6ea13b] text-[70px] animate-spin`} />
      <span className="text-[16px] font-SenSans text-[#6ea13b]">
        Laden der Benutzerliste...
      </span>
    </div>
  );
};

const UserList = () => {
  const dispatch = useDispatch();
  const [curPage, setCurPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleteUserLoading, setIsDeleteUserLoading] = useState(false);
  const [searchParam, setSearchParam] = useState("");
  const [selectedUser, setSelectedUser] = useState(null);
  const [showUserModal, setShowUserModal] = useState(false);
  const [showDeleteUserModal, setShowDeleteUserModal] = useState(false);
  const [sort, setSort] = useState({
    column: "createdAt",
    sortDirection: "desc",
  });
  const { users: result } = useSelector((state) => state.user);
  const { user: currentUser } = useSelector((state) => state.auth);

  const handleAddUserModal = () => {
    setSelectedUser(null);
    setShowUserModal(true);
  };

  const handleEditUserModal = (user) => {
    setSelectedUser(user);
    setShowUserModal(true);
  };

  const handleCloseUserModal = () => {
    setShowUserModal(false);
    setSelectedUser(null);
  };

  const columns = useMemo(() => [
    {
      selector: (row, index) => index + 1 + (curPage - 1) * 10,
      width: "50px",
    },
    {
      cell: (row) => (
        <img
          src={row.avatar ? row.avatar : BlankAvatarSVG}
          className="w-[2rem] h-[2rem] rounded-full"
        />
      ),
      width: "50px",
      style: {
        paddingRight: 0,
      },
    },
    {
      name: "Email",
      columnName: "email",
      selector: (row) => row.email,
      sortable: true,
      minWidth: "250px",
    },
    {
      name: "Vollständiger Name",
      columnName: "firstname",
      selector: (row) => row.firstname + " " + row.lastname,
      sortable: true,
      minWidth: "150px",
    },
    {
      name: "Erstellt Am",
      columnName: "createdAt",
      selector: (row) => moment(row.createdAt).format("ll"),
      sortable: true,
      minWidth: "120px",
      hide: Media.LG,
    },
    {
      name: "Status",
      cell: (row) => (
        <div className="text-[10px]">
          {row.active ? (
            <p className="px-2 py-1 text-[#0ab39c] rounded-sm bg-[rgba(10,179,156,.1)] font-bold">
              AKTIV
            </p>
          ) : (
            <p className="px-2 py-1 text-[#f06548] rounded-sm bg-[rgba(240,101,72,.1)] font-bold">
              PENDING
            </p>
          )}
        </div>
      ),
      minWidth: "100px",
    },
    {
      name: "Vertragspartner Nr",
      columnName: "referralCode",
      selector: (row) => row.referralCode,
      sortable: true,
      minWidth: "150px",
    },
    {
      name: "IBAN",
      columnName: "iban",
      selector: (row) => row.iban,
      sortable: true,
      minWidth: "150px",
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="flex justify-end items-center space-x-2 text-[16px] cursor-pointer">
          <FaUserEdit
            className="text-[#6ea13b] text-[20px] cursor-pointer"
            onClick={() => handleEditUserModal(row)}
          />
          {currentUser?.role === "admin" && (
            <FaUserTimes
              className="text-red-600 text-[20px] cursor-pointer"
              onClick={() => {
                setShowDeleteUserModal(true);
                setSelectedUser(row);
              }}
            />
          )}
        </div>
      ),
      right: true,
      maxWidth: "70px",
    },
  ]);

  useEffect(() => {
    fetchUsers();
  }, [sort, searchParam, curPage]);

  const fetchUsers = () => {
    setIsLoading(true);
    dispatch(
      getUsers(searchParam, 10, curPage, sort.column, sort.sortDirection)
    )
      .then(() => {
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const handlePageChange = (page) => {
    setCurPage(page);
  };

  const handleSort = (column, sortDirection) => {
    setSort({
      column: column.columnName,
      sortDirection,
    });
  };

  const handleDeleteUser = () => {
    if (selectedUser) {
      setIsDeleteUserLoading(true);
      dispatch(deleteUser({ userId: selectedUser.id }))
        .then(() => {
          setIsDeleteUserLoading(false);
          setShowDeleteUserModal(false);
          fetchUsers();
        })
        .catch(() => {
          setIsDeleteUserLoading(false);
          setShowDeleteUserModal(false);
        });
    }
  };

  return (
    <>
      <div className="w-[100%] h-[100%] overflow-x-hidden bg-[#f4f6fa]">
        <Header />
        <div className="max-w-[1600px] pt-[150px] mx-auto h-full flex flex-col justify-start items-start bg-white px-12 -md:px-4 overflow-y-auto profile-container min-h-[calc(100vh-116px)] detail-modal">
          <div className="w-full flex flex-row -lg:flex-col -lg:space-y-2 justify-between items-center mb-4">
            <span className="text-[26px] font-RNSSanzBold font-bold text-[#3D474D] mb-2 -lg:w-full">
              Benutzerverwaltung
            </span>
            <div className="flex -lg:flex-col items-center -lg:items-start -lg:space-x-0 -lg:space-y-2 space-x-2 -lg:w-full">
              <div className="relative w-full">
                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                  <svg
                    aria-hidden="true"
                    className="w-4 h-4 text-gray-500"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </div>
                <input
                  id="search"
                  name="search"
                  type="search"
                  value={searchParam}
                  onInput={(e) => setSearchParam(e.target.value)}
                  placeholder="Suchen..."
                  className={`border border-gray-300 text-gray-900 text-sm rounded-lg focus:border-[#6ea13b] focus:outline-none block w-full pl-10 p-1.5`}
                />
              </div>
              <FaUserPlus
                className="text-[24px] text-[#6ea13b] cursor-pointer"
                onClick={() => handleAddUserModal()}
              />
            </div>
          </div>
          <div className="w-[100%] data-table">
            <DataTable
              columns={columns}
              data={result.users}
              pagination
              paginationServer
              paginationTotalRows={result.count}
              progressPending={isLoading}
              progressComponent={<CustomLoader />}
              onChangePage={handlePageChange}
              sortServer
              onSort={handleSort}
              paginationComponentOptions={{
                noRowsPerPage: true,
              }}
              noDataComponent={"Es gibt keine Datensätze zum Anzeigen"}
            />
          </div>
        </div>
        <Footer />
      </div>
      {showUserModal && (
        <UserModal
          onClose={() => handleCloseUserModal()}
          selectedUser={selectedUser}
          fetchUsers={() => fetchUsers()}
        />
      )}
      {showDeleteUserModal && (
        <ConfirmModal
          title={"Benutzer entfernen"}
          description={"Möchten Sie diesen Benutzer entfernen?"}
          confirmTitle={"Löschen"}
          cancelTitle={"Abbrechen"}
          handleConfirm={() => handleDeleteUser()}
          handleCancel={() => setShowDeleteUserModal(false)}
          isLoading={isDeleteUserLoading}
        />
      )}
    </>
  );
};

export default UserList;
