import React, { useEffect, useState } from "react";
import ProfileNavigation from "./ProfileNavigation";
import General from "./General";
import Password from "./Password";
import Header from "../components/Header";
import Footer from "../components/Footer";

const Profile = () => {
  const [active, setActive] = useState(0);

  return (
    <div className="bg-[#f4f6fa]">
      <Header />
      <div className="max-w-[1300px] pt-[120px] mx-auto h-full flex flex-col justify-start items-start bg-white px-12 -md:px-4 space-y-8 overflow-y-auto profile-container min-h-[calc(100vh-231px)]">
        <ProfileNavigation
          active={active}
          onClick={(id) => {
            setActive(id);
          }}
          children={active == 0 ? <General /> : <Password />}
        />
      </div>
      <Footer />
    </div>
  );
};

export default Profile;
