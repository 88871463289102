import React, { createContext, useState, useRef } from "react";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

export const ConnectContext = createContext({
  selectedProducts: [],
  type: undefined,
  interval: 1,
  firstname: null,
  lastname: null,
  street: null,
  street2: null,
  company: null,
  houseNo: null,
  houseNo2: null,
  postcode: null,
  postcode2: null,
  location: null,
  location2: null,
  degree: null,
  phone: null,
  email: null,
  referral: null,
  health: null,
  insurance: undefined,
  startDate: null,
  insuranceNo: null,
  completedFirstStep: false,
  completedSecondStep: false,
  completedThirdStep: false,
  setSelectedProducts: () => {},
  setType: () => {},
  setInterval: () => {},
  setFirstname: () => {},
  setLastname: () => {},
  setStreet: () => {},
  setStreet2: () => {},
  setCompany: () => {},
  setHouseNo: () => {},
  setHouseNo2: () => {},
  setPostcode: () => {},
  setPostcode2: () => {},
  setLocation: () => {},
  setLocation2: () => {},
  setDegree: () => {},
  setPhone: () => {},
  setEmail: () => {},
  setReferral: () => {},
  setHealth: () => {},
  setInsurance: () => {},
  setStartDate: () => {},
  setInsuranceNo: () => {},
  setSignImage: () => {},
  setCompletedFirstStep: () => {},
  setCompletedSecondStep: () => {},
  setCompletedThirdStep: () => {},

  errorAlert: () => {},
  successAlert: () => {},
});

const ConnectProvider = ({ children }) => {
  const [selectedProducts, setSelectedProducts] = useState([]);
  const { user: currentUser } = useSelector((state) => state.auth);

  const [type, setType] = useState(undefined);
  const [interval, setInterval] = useState(1);
  const [firstname, setFirstname] = useState(null);
  const [lastname, setLastname] = useState(null);
  const [street, setStreet] = useState(null);
  const [houseNo, setHouseNo] = useState(null);
  const [houseNo2, setHouseNo2] = useState(null);
  const [postcode, setPostcode] = useState(null);
  const [location, setLocation] = useState(null);
  const [street2, setStreet2] = useState(null);
  const [company, setCompany] = useState(null);
  const [postcode2, setPostcode2] = useState(null);
  const [location2, setLocation2] = useState(null);
  const [degree, setDegree] = useState(1);
  const [phone, setPhone] = useState(null);
  const [email, setEmail] = useState(null);
  const [referral, setReferral] = useState(
    currentUser?.role == "partner" ? currentUser?.referralCode || null : null
  );

  const [health, setHealth] = useState(null);
  const [insurance, setInsurance] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [insuranceNo, setInsuranceNo] = useState(null);
  const [signImage, setSignImage] = useState(null);

  const [completedFirstStep, setCompletedFirstStep] = useState(false);
  const [completedSecondStep, setCompletedSecondStep] = useState(false);
  const [completedThirdStep, setCompletedThirdStep] = useState(false);

  const errorAlert = (msg) => {
    toast.error(msg, {
      position: "top-center",
      theme: "colored",
      hideProgressBar: false,
      autoClose: 5000,
    });
  };

  const successAlert = (msg) => {
    toast.success(msg, {
      position: "top-center",
      theme: "colored",
      hideProgressBar: false,
      autoClose: 5000,
    });
  };

  return (
    <ConnectContext.Provider
      value={{
        selectedProducts,
        type,
        interval,
        firstname,
        lastname,
        street,
        street2,
        company,
        houseNo,
        houseNo2,
        postcode,
        postcode2,
        location,
        location2,
        degree,
        phone,
        email,
        referral,
        health,
        insurance,
        startDate,
        insuranceNo,
        signImage,
        completedFirstStep,
        completedSecondStep,
        completedThirdStep,
        setSelectedProducts,
        setType,
        setInterval,
        setFirstname,
        setLastname,
        setStreet,
        setStreet2,
        setCompany,
        setHouseNo,
        setHouseNo2,
        setPostcode,
        setPostcode2,
        setLocation,
        setLocation2,
        setDegree,
        setPhone,
        setEmail,
        setReferral,
        setHealth,
        setInsurance,
        setStartDate,
        setInsuranceNo,
        setSignImage,
        errorAlert,
        successAlert,
        setCompletedFirstStep,
        setCompletedSecondStep,
        setCompletedThirdStep,
      }}
    >
      {children}
    </ConnectContext.Provider>
  );
};

export default ConnectProvider;
