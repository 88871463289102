import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { useTranslation } from "react-i18next";
import { register } from "../../actions/auth";
import { CgSpinner } from "react-icons/cg";
import Header from "../components/Header";
import Footer from "../components/Footer";
import {
  required,
  vusername,
  validEmail,
  vpassword,
  vpasswordConfirm,
} from "../../utils";

const RegisterForPartner = () => {
  const { t } = useTranslation();
  const form = useRef();
  const checkBtn = useRef();
  const navigation = useNavigate();

  const [company, setCompany] = useState("");
  const [iban, setIBAN] = useState("");
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const onChangeCompany = (e) => {
    const company = e.target.value;
    setCompany(company);
  }
  
  const onChangeIBAN = (e) => {
    const iban = e.target.value;
    setIBAN(iban);
  }

  const onChangeFirstname = (e) => {
    const firstname = e.target.value;
    setFirstname(firstname);
  };

  const onChangeLastname = (e) => {
    const lastname = e.target.value;
    setLastname(lastname);
  };

  const onChangeEmail = (e) => {
    const email = e.target.value;
    setEmail(email);
  };

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };

  const onConfirmPassword = (e) => {
    const password = e.target.value;
    setPasswordConfirm(password);
  };

  const handleRegister = (e) => {
    e.preventDefault();
    setIsLoading(true);
    form.current.validateAll();
    if (checkBtn.current.context._errors.length === 0) {
      dispatch(register(company, firstname, lastname, email, password, iban))
        .then(() => {
          setIsLoading(false);
          navigation("/confirmation");
        })
        .catch(() => {
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  };

  return (
    <div className="bg-[#f4f6fa]">
      <Header />
      <div className="h-[calc(100vh-231px)] min-h-[900px] flex bg-gray-100">
        <div className="md:w-[600px] -md:w-[95%] m-auto bg-white rounded-lg border border-primaryBorder shadow-default py-10 px-12 -sm:px-8">
          <h1 className="text-xl font-bold text-primary mt-4 mb-12 text-center text-[#6ea13b]">
            {t("auth.register.text")}
          </h1>
          <Form
            onSubmit={handleRegister}
            ref={form}
            className="flex flex-col justify-start itesm-center space-y-6 mt-[20px] w-full"
          >
            <>
              <div className="form-group">
                <Input
                  type="text"
                  className="form-control !bg-white"
                  name="company"
                  value={company}
                  onChange={onChangeCompany}
                  validations={[required]}
                  placeholder={t("auth.register.placeholder.company")}
                />
                {/* <p className="text-[12px] px-2 text-center">
                  Wir buchen nichts ab. Die IBAN wird lediglich zur Auszahlung deiner Vermittlungsprämie benötigt. (3 Monate nach Erstlieferung)
                </p> */}
              </div>
              {/* <div className="form-group">
                <Input
                  type="text"
                  className="form-control !bg-white"
                  name="iban"
                  value={iban}
                  onChange={onChangeIBAN}
                  validations={[required]}
                  placeholder={t("auth.register.placeholder.iban")}
                />
              </div> */}
              <div className="flex flex-row -sm:flex-col -sm:space-y-4 justify-between items-start space-x-2 -sm:space-x-0">
                <div className="form-group w-full">
                  <Input
                    type="text"
                    className="form-control !bg-white"
                    name="firstname"
                    value={firstname}
                    onChange={onChangeFirstname}
                    validations={[required, vusername]}
                    placeholder={t("auth.register.placeholder.firstname")}
                  />
                </div>
                <div className="form-group w-full">
                  <Input
                    type="text"
                    className="form-control !bg-white"
                    name="lastname"
                    value={lastname}
                    onChange={onChangeLastname}
                    validations={[required, vusername]}
                    placeholder={t("auth.register.placeholder.lastname")}
                  />
                </div>
              </div>
              <div className="form-group">
                <Input
                  type="text"
                  className="form-control !bg-white"
                  name="email"
                  value={email}
                  onChange={onChangeEmail}
                  validations={[required, validEmail]}
                  placeholder={t("auth.register.placeholder.email")}
                />
              </div>
              <div className="form-group">
                <Input
                  type="password"
                  className="form-control !bg-white"
                  name="password"
                  value={password}
                  onChange={onChangePassword}
                  validations={[required, vpassword]}
                  placeholder={t("auth.register.placeholder.password")}
                />
              </div>
              <div className="form-group">
                <Input
                  type="password"
                  className="form-control !bg-white"
                  name="passwordConfirm"
                  value={passwordConfirm}
                  onChange={onConfirmPassword}
                  validations={[required, vpasswordConfirm]}
                  placeholder={t("auth.register.placeholder.confirm")}
                />
              </div>
              <div className="form-group">
                <button disabled={isLoading}>
                  {isLoading ? (
                    <CgSpinner
                      className={`text-white text-[30px] animate-spin`}
                    />
                  ) : (
                    <span className="uppercase text-white">
                      {t("auth.register.title")}
                    </span>
                  )}
                </button>
              </div>
            </>
            <CheckButton style={{ display: "none" }} ref={checkBtn} />
          </Form>
          <div className="flex flex-row justify-center items-center space-x-2 mt-4 -sm:space-x-0 -sm:flex-col -sm:space-y-2">
            <p className="text-[12px]">Sie haben bereits ein Konto?</p>
            <p
              className="text-[12px] text-[#6ea13b] font-bold cursor-pointer"
              onClick={() => navigation("/login")}
            >
              Jetzt anmelden
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default RegisterForPartner;
