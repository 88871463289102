import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ConnectContext } from "../../provider/ConnectProvider";
import { ProductCard } from "../landing/Section";
import { CgSpinner } from "react-icons/cg";
import { useSelector } from "react-redux";
import {
  HiOutlineMail,
  HiOutlinePhone,
  HiOutlineUser,
  HiOutlineLocationMarker,
} from "react-icons/hi";
import { FaBirthdayCake } from "react-icons/fa";
import { GiHospitalCross } from "react-icons/gi";
import { AiOutlineFieldNumber, AiOutlineMan } from "react-icons/ai";
import { SiOpslevel } from "react-icons/si";
import { BsGenderAmbiguous, BsArrowLeft } from "react-icons/bs";
import moment from "moment";
import api from "../../services/api";
import DeliverSVG from "../../assets/svgs/deliver.svg";
import OrderConfirmModal from "./OrderConfirmModal";

const Section = () => {
  const navigation = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [showCount, setShowCount] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const { user: currentUser } = useSelector((state) => state.auth);
  const {
    selectedProducts,
    type,
    interval,
    firstname,
    lastname,
    street,
    street2,
    company,
    houseNo,
    houseNo2,
    postcode,
    postcode2,
    location,
    location2,
    degree,
    phone,
    email,
    health,
    insurance,
    startDate,
    insuranceNo,
    referral,
    signImage,
    successAlert,
    errorAlert,
    completedFirstStep,
    completedSecondStep,
    completedThirdStep,
    setSelectedProducts,
  } = useContext(ConnectContext);

  useEffect(() => {
    if (!completedFirstStep && !completedSecondStep && !completedThirdStep) {
      navigation("/order/select");
    }
  }, [completedFirstStep, completedSecondStep, completedThirdStep]);

  useEffect(() => {
    if (showCount) {
      setTimeout(() => {
        setSelectedProducts([]);
        setIsLoading(false);
        window.location.href = "https://pflegeimkarton.de/";
      }, 10000);
    }
  }, [showCount]);

  const handleSaveOrder = (e) => {
    setIsLoading(true);
    // e.preventDefault();
    let formData = {
      type,
      interval,
      email,
      firstname,
      lastname,
      street,
      street2,
      company,
      houseNo,
      houseNo2,
      postcode,
      postcode2,
      location,
      location2,
      degree,
      phone,
      health,
      insurance,
      insuranceNo,
      startDate,
      signImage,
      referral,
      products: JSON.stringify(
        selectedProducts.map((p) => ({ ...p, date: new Date() }))
      ),
    };
    api
      .post("/api/order/add", formData)
      .then((res) => {
        if (res && res.data.success) {
          // successAlert(res.data.message);
          setShowConfirmModal(true);
          setShowCount(true);
        } else {
          setShowConfirmModal(false);
          errorAlert(res.data.message);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log("error: ", error);
        setIsLoading(false);
        setShowConfirmModal(false);
      });
  };

  return (
    <div className="bg-[#f4f6fa] w-[100%] pb-[100px] pt-[50px]">
      <div className="max-w-[1250px] flex flex-col justify-center items-center -lg:items-center space-x-0 space-y-8 mx-auto px-12 -sm:px-4">
        <p className="text-[40px] -sm:text-[24px] text-[#6ea13b] font-bold">
          Bestellzusammenfassung
        </p>
        {/* <div className='flex flex-row justify-center items-center space-x-2 -sm:px-2 text-justify'>
          <img src={DeliverSVG} className='w-[24px] h-[24px]' />
          <div className='text-[14px] w-full'>
            <strong className='pr-1'>Ihre Lieferung wird in 3-5 Werktagen bei ihnen eintreffen.</strong>
            Nach Versand erhalten Sie eine Versandbestätigung per Email.
          </div>
        </div> */}
        {showCount && (
          <p className="text-[14px]">
            Sie werden in{" "}
            <span className="font-bold text-[20px] text-[#6ea13b]">10</span>{" "}
            Sekunden auf die Startseite zurückgeschickt.
            <a className="underline" href="https://pflegeimkarton.de/">
              {" "}
              Jetzt zurückkehren.
            </a>
          </p>
        )}
        <p className="text-[20px]">Hier finden Sie Bestelldetails.</p>
        <div className="flex flex-row -lg:flex-col justify-center items-start w-full space-x-12 -lg:space-x-0 -lg:space-y-8">
          <div className="w-[50%] -lg:w-[100%]">
            <div className="flex flex-row justify-start items-center space-x-2 my-4">
              <span className="text-[16px]">
                Liste der ausgewählten Produkte
              </span>
            </div>
            {selectedProducts.map((product, index) => {
              return (
                <ProductCard
                  key={index}
                  index={index}
                  title={product.title}
                  stock={product.stock}
                  photo={product.photo}
                  showActionBtn={false}
                  isSelected={true}
                  productSize={product.size}
                  from="right"
                />
              );
            })}
          </div>
          <div className="w-[50%] -lg:w-[100%] bg-white px-8 py-4 rounded-lg">
            <div className="flex flex-col justify-start items-start space-y-4">
              <div className="flex flex-row justify-start items-center space-x-2 my-4">
                <span className="text-[20px] font-bold">Kundendetails</span>
              </div>
              <div className="w-full flex flex-col justify-start items-start space-y-8">
                <div className="flex flex-row -sm:flex-col justify-start items-start space-x-4 -sm:space-x-0 -sm:space-y-8 w-full">
                  <div className="flex flex-col justify-start items-start space-y-2 w-[50%]">
                    <div className="flex justify-start items-center space-x-2 text-[#6ea13b]">
                      <HiOutlineUser className="text-[20px]" />
                      <span className="font-bold text-[#6ea13b]">
                        Vorname & Nachname
                      </span>
                    </div>
                    <p className="text-[12px]">
                      {firstname ? firstname : "Keine"}{" "}
                      {lastname ? lastname : "Keine"}
                    </p>
                  </div>
                  <div className="flex flex-col justify-start items-start space-y-2 w-[50%]">
                    <div className="flex justify-start items-center space-x-2 text-[#6ea13b]">
                      <HiOutlineMail className="text-[20px]" />
                      <span className="font-bold text-[#6ea13b]">E-Mail</span>
                    </div>
                    <p className="text-[12px]">{email ? email : "Keine"}</p>
                  </div>
                </div>
                <div className="flex flex-row -sm:flex-col justify-start items-start space-x-4 -sm:space-x-0 -sm:space-y-8 w-full">
                  <div className="flex flex-col justify-start items-start space-y-2 w-[50%]">
                    <div className="flex justify-start items-center space-x-2 text-[#6ea13b]">
                      <HiOutlinePhone className="text-[20px]" />
                      <span className="font-bold text-[#6ea13b]">
                        Telefonnummer
                      </span>
                    </div>
                    <p className="text-[12px]">{phone ? phone : "Keine"}</p>
                  </div>
                  <div className="flex flex-col justify-start items-start space-y-2 w-[50%]">
                    <div className="flex justify-start items-center space-x-2 text-[#6ea13b]">
                      <FaBirthdayCake className="text-[20px]" />
                      <span className="font-bold text-[#6ea13b]">
                        Geburtstag
                      </span>
                    </div>
                    <p className="text-[12px]">
                      {startDate
                        ? moment(startDate).format("YYYY-MM-DD")
                        : "Keine"}
                    </p>
                  </div>
                </div>
                <div className="flex flex-row -sm:flex-col justify-start items-start space-x-4 -sm:space-x-0 -sm:space-y-8 w-full">
                  <div className="flex flex-col justify-start items-start space-y-2 w-[50%]">
                    <div className="flex justify-start items-center space-x-2">
                      <HiOutlineLocationMarker className="text-[20px] text-[#6ea13b]" />
                      <span className="font-bold text-[#6ea13b]">Ort</span>
                    </div>
                    <p className="text-[12px]">
                      {street ? street : "Keine"} {houseNo ? houseNo : "Keine"}{" "}
                      <br />
                      {postcode ? postcode : "Keine"}{" "}
                      {location ? location : "Keine"}
                    </p>
                  </div>
                  <div className="flex flex-col justify-start items-start space-y-2 w-[50%]">
                    <div className="flex justify-start items-center space-x-2">
                      <BsGenderAmbiguous className="text-[20px] text-[#6ea13b]" />
                      <span className="font-bold text-[#6ea13b]">Anrede</span>
                    </div>
                    <p className="text-[12px]">
                      {type ? (type === "man" ? "Herr" : "Frau") : "Keine"}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col justify-start items-start space-y-4 mt-12">
              <div className="flex flex-row justify-start items-center space-x-2 my-4">
                <span className="text-[20px] font-bold">
                  Versicherten Einzelheiten
                </span>
              </div>
              <div className="w-full flex flex-col justify-start items-start space-y-8">
                <div className="flex flex-row -sm:flex-col justify-start items-start space-x-4 -sm:space-x-0 -sm:space-y-8 w-full">
                  <div className="flex flex-col justify-start items-start space-y-2 w-[50%]">
                    <div className="flex justify-start items-center space-x-2 text-[#6ea13b]">
                      <GiHospitalCross className="text-[20px]" />
                      <span className="font-bold text-[#6ea13b]">
                        Versicherten Typ
                      </span>
                    </div>
                    <p className="text-[12px]">
                      {insurance ? insurance : "Keine"}
                    </p>
                  </div>
                  <div className="flex flex-col justify-start items-start space-y-2 w-[50%]">
                    <div className="flex justify-start items-center space-x-2 text-[#6ea13b]">
                      <AiOutlineFieldNumber className="text-[20px]" />
                      <span className="font-bold text-[#6ea13b]">
                        Versichertennummer
                      </span>
                    </div>
                    <p className="text-[12px]">
                      {insuranceNo ? insuranceNo : "Keine"}
                    </p>
                  </div>
                </div>
                <div className="flex flex-row -sm:flex-col justify-start items-start space-x-4 -sm:space-x-0 -sm:space-y-8 w-full">
                  <div className="flex flex-col justify-start items-start space-y-2 w-[50%]">
                    <div className="flex justify-start items-center space-x-2">
                      <SiOpslevel className="text-[20px] text-[#6ea13b]" />
                      <span className="font-bold text-[#6ea13b]">
                        Pflegegrad
                      </span>
                    </div>
                    <p className="text-[12px]">
                      {degree ? "Pflegegrad " + degree : "Keine"}
                    </p>
                  </div>
                  {health && (
                    <div className="flex flex-col justify-start items-start space-y-2 w-[50%]">
                      <div className="flex justify-start items-center space-x-2 text-[#6ea13b]">
                        <GiHospitalCross className="text-[20px]" />
                        <span className="font-bold text-[#6ea13b]">
                          Versicherung/Krankenkasse
                        </span>
                      </div>
                      <p className="text-[12px]">{health ? health : "Keine"}</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="flex flex-col justify-start items-start space-y-4 mt-12">
              <div className="flex flex-row justify-start items-center space-x-2 my-4">
                <span className="text-[20px] font-bold">Unterschrift</span>
              </div>
              <div className="w-full flex flex-col justify-start items-start space-y-8 px-16 py-8 border rounded-lg">
                {signImage && <img src={signImage} className="w-full" />}
              </div>
            </div>
            <div className="w-full flex flex-row justify-end items-center space-x-4 my-8">
              <div
                className="flex flex-row justify-start items-center space-x-2 cursor-pointer"
                onClick={() => {
                  navigation("/order/signature");
                }}
              >
                <BsArrowLeft />
                <p className="text-[12px] font-bold">Zurück</p>
              </div>
              <div
                className={`flex flex-row justify-center items-center rounded-[10px] py-2 px-8 bg-[#f98918] hover:bg-[#ffc04f] hover:text-black text-[14px] text-white font-bold cursor-pointer ${
                  isLoading ? "opacity-50" : ""
                }`}
                onClick={() => {
                  handleSaveOrder();
                }}
              >
                {isLoading ? (
                  <CgSpinner
                    className={`text-white text-[20px] animate-spin`}
                  />
                ) : (
                  "Bestellung anlegen"
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {showConfirmModal && (
        <OrderConfirmModal handleCancel={() => setShowConfirmModal(false)} />
      )}
    </div>
  );
};

export default Section;
