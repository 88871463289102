import React, { useEffect } from 'react';
import Header from '../components/Header';
import Section from './Section';
import Footer from '../components/Footer';
import Menu from '../components/Menu';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

const Landing = () => {
  const { user: currentUser } = useSelector(state => state.auth);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
  }, [])

  if (currentUser && currentUser.role === 'admin') {
    return <Navigate to="/admin/order-list" />
  }
  
  return (
    <div className='bg-[#f4f6fa]'>
      <Header />
      <Menu id={0} />
      <Section />
      <Footer />
    </div>
  )
}

export default Landing;