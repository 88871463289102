import React, { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux';
import { logout } from '../../actions/auth';
import Event from '../../utils/Event';

const RequireAuthLayout = ({ children }) => {
  const navigation = useNavigate();
  const dispatch = useDispatch();

  const logOut = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

  useEffect(() => {
    Event.on("logout", () => {
      logOut();
      navigation('/login');
    });
    return () => Event.remove("logout")
  }, [logOut]);

  return (
    <>
      {children}
    </>
  )
}

export default RequireAuthLayout;