import React, { useRef, useState } from "react";
import CloseSVG from "../../../assets/svgs/close.svg";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { CgSpinner } from "react-icons/cg";
import {
  required,
  vusername,
  validEmail,
  vpassword,
  vpasswordConfirm,
} from "../../../utils";
import { addUser, updateUser } from "../../../actions/admin";
import { useDispatch } from "react-redux";

const UserModal = ({ onClose, selectedUser, fetchUsers }) => {
  const form = useRef();
  const checkBtn = useRef();
  const dispatch = useDispatch();

  const [firstname, setFirstname] = useState(selectedUser?.firstname || "");
  const [lastname, setLastname] = useState(selectedUser?.lastname || "");
  const [email, setEmail] = useState(selectedUser?.email || "");
  const [iban, setIBAN] = useState(selectedUser?.iban || "");
  const [taxID, setTaxID] = useState(selectedUser?.banner || "");
  const [company, setCompany] = useState(selectedUser?.company || "");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [street, setStreet] = useState(selectedUser?.street || "");
  const [houseNo, setHouseNo] = useState(selectedUser?.houseNo || "");
  const [postcode, setPostcode] = useState(selectedUser?.postcode || "");
  const [location, setLocation] = useState(selectedUser?.location || "");
  const [isLoading, setIsLoading] = useState(false);

  const onChangeFirstname = (e) => {
    const firstname = e.target.value;
    setFirstname(firstname);
  };

  const onChangeLastname = (e) => {
    const lastname = e.target.value;
    setLastname(lastname);
  };

  const onChangeEmail = (e) => {
    const email = e.target.value;
    setEmail(email);
  };

  const onChangeIBAN = (e) => {
    const iban = e.target.value;
    setIBAN(iban);
  };

  const onChangeTaxID = (e) => {
    const taxID = e.target.value;
    setTaxID(taxID);
  }

  const onChangeCompany = (e) => {
    setCompany(e.target.value);
  };

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };

  const onConfirmPassword = (e) => {
    const password = e.target.value;
    setPasswordConfirm(password);
  };

  const handleRegister = (e) => {
    e.preventDefault();
    setIsLoading(true);
    form.current.validateAll();
    if (checkBtn.current.context._errors.length === 0) {
      if (selectedUser) {
        const payload = {
          userId: selectedUser.id,
          firstname,
          lastname,
          email,
          iban,
          company,
          taxID,
          roleId: selectedUser.roleId,
          street,
          houseNo,
          postcode,
          location,
        };
        dispatch(updateUser(payload)).then(() => {
          setIsLoading(false);
          fetchUsers();
          onClose();
        });
      } else {
        const payload = {
          firstname,
          lastname,
          email,
          password,
          iban,
          company,
          taxID,
          street,
          houseNo,
          postcode,
          location,
          roleId: 1,
        };
        dispatch(addUser(payload)).then(() => {
          setIsLoading(false);
          fetchUsers();
          onClose();
        });
      }
    } else {
      setIsLoading(false);
    }
  };
  return (
    <div
      className={`fixed h-full w-full top-0 left-0 bg-[rgba(138,148,153,0.7)] bg-opacity-80 flex items-start justify-center z-30 overflow-y-scroll overflow-x-hidden detail-modal`}
    >
      <div className="relative mx-auto rounded-lg bg-white my-[50px] -md:w-[90%] md:w-[600px]">
        <div className="w-full min-h-[80px] px-8 py-4 border-b-[1px] border-[#e1e4e6] bg-white rounded-tl-lg rounded-tr-lg flex flex-row justify-between items-center">
          <div className="text-[20px] font-RNSSanzBold">
            Benutzer {selectedUser ? "bearbeiten " : "hinzufügen "}
          </div>
          <div
            className="flex flex-row justify-center items-center bg-[#0000000d] w-[30px] h-[30px] rounded-full cursor-pointer"
            onClick={onClose}
          >
            <img src={CloseSVG} className="w-[20px] h-[20px]" />
          </div>
        </div>
        <div className="w-full flex flex-col justify-center items-center py-4">
          <Form
            onSubmit={handleRegister}
            autoComplete="off"
            ref={form}
            className="flex flex-col justify-start itesm-center space-y-6 w-[100%] pt-4"
          >
            <div className="flex flex-row -sm:flex-col -sm:space-y-2 justify-between space-x-2 -sm:space-x-0 items-start px-6">
              <div className="form-group w-full">
                <label
                  htmlFor="firstname"
                  className="text-[12px] font-RNSSanzMedium pb-1 text-[#8A9499]"
                >
                  Vorname
                </label>
                <Input
                  type="text"
                  className="form-control !bg-white"
                  name="firstname"
                  value={firstname}
                  onChange={onChangeFirstname}
                  validations={[required, vusername]}
                  autoComplete="off"
                />
              </div>
              <div className="form-group w-full">
                <label
                  htmlFor="lastname"
                  className="text-[12px] font-RNSSanzMedium pb-1 text-[#8A9499]"
                >
                  Nachname
                </label>
                <Input
                  type="text"
                  className="form-control !bg-white"
                  name="lastname"
                  value={lastname}
                  onChange={onChangeLastname}
                  validations={[required, vusername]}
                  autoComplete="off"
                />
              </div>
            </div>
            <div className="form-group px-6">
              <label
                htmlFor="email"
                className="text-[12px] font-RNSSanzMedium pb-1 text-[#8A9499]"
              >
                E-Mail Adresse
              </label>
              <Input
                type="text"
                className="form-control !bg-white !w-[100%]"
                name="email"
                value={email}
                onChange={onChangeEmail}
                validations={[required, validEmail]}
                autoComplete="off"
              />
            </div>
            {selectedUser?.roleId == 2 && (
              <div className="form-group px-6">
                <label
                  htmlFor="email"
                  className="text-[12px] font-RNSSanzMedium pb-1 text-[#8A9499]"
                >
                  Firma
                </label>
                <Input
                  type="text"
                  className="form-control !bg-white !w-[100%]"
                  name="company"
                  value={company}
                  onChange={onChangeCompany}
                />
              </div>
            )}

            {selectedUser?.roleId == 2 && (
              <div className="form-group px-6">
                <label
                  htmlFor="email"
                  className="text-[12px] font-RNSSanzMedium pb-1 text-[#8A9499]"
                >
                  IBAN
                </label>
                <Input
                  type="text"
                  className="form-control !bg-white !w-[100%]"
                  name="iban"
                  value={iban}
                  onChange={onChangeIBAN}
                />
              </div>
            )}
            {selectedUser?.roleId == 2 && (
              <div className="form-group px-6">
                <label
                  htmlFor="email"
                  className="text-[12px] font-RNSSanzMedium pb-1 text-[#8A9499]"
                >
                  Steuernummer
                </label>
                <Input
                  type="text"
                  className="form-control !bg-white !w-[100%]"
                  name="taxID"
                  value={taxID}
                  onChange={onChangeTaxID}
                />
              </div>
            )}
            <div className="flex flex-row -sm:flex-col -sm:space-y-2 justify-between space-x-2 -sm:space-x-0 items-start px-6">
              <div className="form-group w-full">
                <label
                  htmlFor="firstname"
                  className="text-[12px] font-RNSSanzMedium pb-1 text-[#8A9499]"
                >
                  Straße
                </label>
                <Input
                  type="text"
                  className="form-control !bg-white"
                  name="street"
                  value={street || ""}
                  onChange={(e) => setStreet(e.target.value)}
                  autoComplete="off"
                />
              </div>
              <div className="form-group w-full">
                <label
                  htmlFor="lastname"
                  className="text-[12px] font-RNSSanzMedium pb-1 text-[#8A9499]"
                >
                  Haus Nummer
                </label>
                <Input
                  type="text"
                  className="form-control !bg-white"
                  name="houseNo"
                  value={houseNo || ""}
                  onChange={(e) => setHouseNo(e.target.value)}
                  autoComplete="off"
                />
              </div>
            </div>
            <div className="flex flex-row -sm:flex-col -sm:space-y-2 justify-between space-x-2 -sm:space-x-0 items-start px-6">
              <div className="form-group w-full">
                <label
                  htmlFor="firstname"
                  className="text-[12px] font-RNSSanzMedium pb-1 text-[#8A9499]"
                >
                  PLZ
                </label>
                <Input
                  type="text"
                  className="form-control !bg-white"
                  name="postcode"
                  value={postcode || ""}
                  onChange={(e) => setPostcode(e.target.value)}
                  autoComplete="off"
                />
              </div>
              <div className="form-group w-full">
                <label
                  htmlFor="lastname"
                  className="text-[12px] font-RNSSanzMedium pb-1 text-[#8A9499]"
                >
                  Ort
                </label>
                <Input
                  type="text"
                  className="form-control !bg-white"
                  name="location"
                  value={location}
                  onChange={(e) => setLocation(e.target.value)}
                  autoComplete="off"
                />
              </div>
            </div>
            {!selectedUser ? (
              <>
                <div className="form-group px-6">
                  <label
                    htmlFor="password"
                    className="text-[12px] font-RNSSanzMedium pb-1 text-[#8A9499]"
                  >
                    Passwort
                  </label>
                  <Input
                    type="password"
                    className="form-control !bg-white !w-[100%]"
                    name="password"
                    value={password}
                    onChange={onChangePassword}
                    validations={[required, vpassword]}
                    autoComplete="off"
                  />
                </div>
                <div className="form-group px-6">
                  <label
                    htmlFor="passwordConfirm"
                    className="text-[12px] font-RNSSanzMedium pb-1 text-[#8A9499]"
                  >
                    Bestätigen Sie Ihr Passwort
                  </label>
                  <Input
                    type="password"
                    className="form-control !bg-white !w-[100%]"
                    name="passwordConfirm"
                    value={passwordConfirm}
                    onChange={onConfirmPassword}
                    validations={[required, vpasswordConfirm]}
                    autoComplete="off"
                  />
                </div>
              </>
            ) : null}
            <div className="form-group flex justify-center">
              <button
                disabled={isLoading}
                className="!h-[40px] !mt-4 !w-[100px]"
              >
                {isLoading ? (
                  <CgSpinner
                    className={`text-white text-[30px] animate-spin`}
                  />
                ) : (
                  <span className="text-white">
                    {selectedUser ? "Speichern" : "hinzufügen"}
                  </span>
                )}
              </button>
            </div>
            <CheckButton style={{ display: "none" }} ref={checkBtn} />
          </Form>
        </div>
      </div>
    </div>
  );
};

export default UserModal;
