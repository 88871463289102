import React, { useEffect, useState, useMemo, useContext } from "react";
import DataTable from "react-data-table-component";
import moment from "moment";
import Header from "../components/Header";
import Footer from "../components/Footer";
import api from "../../services/api";
import { CustomLoader } from "../admin/orders";

const ShippingHistory = () => {
  const [curPage, setCurPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [searchParam, setSearchParam] = useState("");

  const [sort, setSort] = useState({
    column: "createdAt",
    sortDirection: "desc",
  });
  const [result, setResult] = useState({
    users: [],
    count: 0,
    hasMore: false,
  });

  const columns = useMemo(() => [
    {
      selector: (row, index) => index + 1 + (curPage - 1) * 20,
      width: "50px",
    },
    {
      name: "Name",
      columnName: "fullname",
      selector: (row) => `${row.order.firstname} ${row.order.lastname}`,
      sortable: true,
      minWidth: "200px",
    },
    {
      name: "Email",
      columnName: "email",
      selector: (row) => row.order.email,
      sortable: true,
    },
    {
      name: "Bestellrythmus",
      columnName: "interval",
      cell: (row) => (
        <div className="text-[10px]">
          {row.order.interval === "1" ? (
            <p className="px-2 py-1 text-[#0ab39c] rounded-sm bg-[rgba(10,179,156,.1)] font-bold">
              Monatlich
            </p>
          ) : (
            <p className="px-2 py-1 text-[#0ab39c] rounded-sm bg-[rgba(10,179,156,.1)] font-bold">
              Alle drei Monate
            </p>
          )}
        </div>
      ),
      sortable: true,
    },
    {
      name: "Versicherung",
      columnName: "insurance",
      selector: (row) => row.order.insurance,
      sortable: true,
    },
    {
      name: "Erstellt Am",
      columnName: "createdAt",
      selector: (row) => moment(row.order.createdAt).format("DD.MM.YYYY"),
      sortable: true,
    },
    {
      name: "LieferungAuf",
      columnName: "deliveriedAt",
      selector: (row) => moment(row.deliveryTime).format("DD.MM.YYYY"),
      sortable: true,
    },
  ]);

  useEffect(() => {
    fetchOrders();
  }, [sort, curPage, searchParam]);

  const fetchOrders = () => {
    setIsLoading(true);
    api
      .post("/api/order/shippingHistory", {
        searchParam: searchParam,
        pageSize: 20,
        pageNumber: curPage,
        column: sort.column,
        sortDirection: sort.sortDirection,
      })
      .then((res) => {
        setResult(res.data);
        setIsLoading(false);
      });
  };

  const handlePageChange = (page) => {
    setCurPage(page);
  };

  const handleSort = (column, sortDirection) => {
    setSort({
      column: column.columnName,
      sortDirection,
    });
  };

  return (
    <div className="w-[100%] h-[100%] overflow-x-hidden bg-[#f4f6fa]">
      <Header />
      <div className="max-w-[1400px] pt-[120px] mx-auto h-full flex flex-col justify-start items-start bg-white px-12 -md:px-4 overflow-y-auto profile-container min-h-[calc(100vh-116px)] detail-modal">
        <div className="w-full flex flex-row -lg:flex-col -lg:space-y-2 justify-between items-center mb-4">
          <span className="text-[26px] font-RNSSanzBold text-[#3D474D] mb-2 -lg:w-full font-bold">
            Versandhistorie
          </span>
          <div className="flex -lg:flex-col items-center -lg:items-start -lg:space-x-0 -lg:space-y-2 space-x-2 -lg:w-full">
            <div className="relative w-full">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <svg
                  aria-hidden="true"
                  className="w-4 h-4 text-gray-500"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </div>
              <input
                id="search"
                name="search"
                type="search"
                value={searchParam}
                onInput={(e) => setSearchParam(e.target.value)}
                placeholder="Suchen..."
                className={`border border-gray-300 text-gray-900 text-sm rounded-lg focus:border-[#6ea13b] focus:outline-none block w-full pl-10 p-1.5`}
              />
            </div>
          </div>
        </div>
        <div className="w-[100%] data-table">
          <DataTable
            columns={columns}
            data={result.users}
            pagination
            paginationServer
            paginationTotalRows={result.count}
            progressPending={isLoading}
            progressComponent={<CustomLoader />}
            paginationPerPage={20}
            paginationRowsPerPageOptions={[20]}
            onChangePage={handlePageChange}
            sortServer
            onSort={handleSort}
            noDataComponent={"Es gibt keine Datensätze zum Anzeigen"}
          />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ShippingHistory;
