import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { BsFillPlusSquareFill, BsInfoCircleFill } from "react-icons/bs";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { GrFormClose } from "react-icons/gr";
import CartSVG from "../../assets/svgs/cart.svg";
import { Products, ProductsTooltips } from "../components/Products";
import ProgressBar from "@ramonak/react-progress-bar";
import { ConnectContext } from "../../provider/ConnectProvider";
import ReactTooltip from "react-tooltip";
import api from "../../services/api";
import { useSelector } from "react-redux";

let maxPrice = 43;

const int_round5 = (num) => {
  return Math.ceil(num / 5) * 5;
};

export const ProductCard = ({
  index,
  isModal,
  orderId,
  title,
  stock,
  photo,
  price,
  isSelected = false,
  isDisabled = false,
  handleSelect,
  handleUnSelect,
  productSize,
  showActionBtn = true,
  tooltipText,
  from = "left",
}) => {
  const [showSizeList, setShowSizeList] = useState(false);
  const [selectedSize, setSelectedSize] = useState("S");
  const [freeCount, setFreeCount] = useState(3);
  const { user: currentUser } = useSelector((state) => state.auth);

  useEffect(() => {
    if (
      currentUser &&
      title === "Saugende Bettschutzeinlage / wiederverwendbar" &&
      from !== "right"
    ) {
      api
        .post("/api/product/free", { isModal, orderId })
        .then((res) => {
          if (res && res.data.count) {
            setFreeCount(3 - res.data.count);
          }
        })
        .catch((err) =>
          console.log("err during get the free product count: ", err)
        );
    }
  }, [title, from, currentUser]);

  useEffect(() => {
    if (title === "Fingerlinge" || title === "Einmalhandschuhe") {
      setShowSizeList(true);
    } else {
      setShowSizeList(false);
    }
  }, [title]);

  if (freeCount === 0) {
    isDisabled = true;
  }

  const sizes =
    title === "Einmalhandschuhe" ? ["S", "M", "L", "XL"] : ["S", "M", "L"];

  return (
    <div
      className={`flex flex-row justify-between items-center min-h-[80px] relative py-1 -sm:min-h-[80px] pr-4 pl-2 bg-white w-full rounded-[10px] mb-3 ${
        isDisabled ? "opacity-50 cursor-not-allowed" : "opacity-100"
      }`}
      style={{
        marginTop: index === 1 && from === "left" ? "50px" : "auto",
      }}
    >
      {index === 1 && from === "left" && (
        <div className="w-full absolute left-0 right-0 top-[-25px] border-t-[2px] border-dashed border-t-[#6ea13b]"></div>
      )}
      <div className="flex flex-row justify-start items-center space-x-4">
        {!isSelected && (
          <div data-tip data-for={`product${index}`}>
            <BsInfoCircleFill className="text-[#6ea13b] text-[20px]" />
          </div>
        )}
        <img src={photo} className="w-[64px] h-[64px] object-contain" />
        <div className="flex flex-col justify-start items-start">
          <div className="font-bold whitespace-normal flex flex-wrap space-x-2 items-center">
            <span className="text-[15px] -sm:text-[14px] font-[600]">
              {title}
            </span>
          </div>
          <div className="text-[12px] text-[#9ca3af]">
            {(index === 0 && from !== "right" && currentUser && (
              <p className="text-left">
                In diesem Jahr stehen ihnen noch{" "}
                <span className="font-bold text-red-600 text-[14px]">
                  {freeCount}
                </span>{" "}
                von 3 Bettschutzeinlagen rechtlich zu.
              </p>
            )) ||
              stock}
          </div>
          {isSelected &&
          title === "Saugende Bettschutzeinlage / wiederverwendbar" ? (
            <p className="text-[10px] text-[#6b7280]">
              Bis zu 3 Stück / Jahr. Versand erfolgt nach Genehmigung durch Ihre
              Kasse!
            </p>
          ) : null}
          {showSizeList ? (
            isSelected ? (
              <p className="text-[12px] md:hidden">
                Size:{" "}
                <span className="text-[#6ea13b] font-bold">{productSize}</span>
              </p>
            ) : (
              <div className="flex flex-row justify-start items-center space-x-4 md:hidden">
                <p className="text-[12px]">
                  Size:{" "}
                  <span className="text-[#6ea13b] font-bold">
                    {selectedSize}
                  </span>
                </p>
                <div className="flex flex-row justify-start items-center space-x-2">
                  {sizes.map((size, index) => {
                    return (
                      <div
                        key={index}
                        className={`border border-[#6ea13b] px-2 py-1 rounded-full cursor-pointer ${
                          selectedSize === size
                            ? "bg-[#6ea13b] text-white"
                            : "text-black"
                        }`}
                        onClick={() => {
                          setSelectedSize(size);
                        }}
                      >
                        <p className="text-[12px] font-bold">{size}</p>
                      </div>
                    );
                  })}
                </div>
              </div>
            )
          ) : null}
        </div>
      </div>
      <div className="flex flex-row justify-end items-center space-x-4">
        {showSizeList ? (
          isSelected ? (
            <p className="text-[12px] -md:hidden">
              Size:{" "}
              <span className="text-[#6ea13b] font-bold">{productSize}</span>
            </p>
          ) : (
            <div className="flex flex-col justify-start items-start space-y-1 -md:hidden">
              <p className="text-[12px]">
                Size:{" "}
                <span className="text-[#6ea13b] font-bold">{selectedSize}</span>
              </p>
              <div className="flex flex-row justify-start items-center space-x-2">
                {sizes.map((size, index) => {
                  return (
                    <div
                      key={index}
                      className={`border border-[#6ea13b] px-2 py-1 rounded-full cursor-pointer ${
                        selectedSize === size
                          ? "bg-[#6ea13b] text-white"
                          : "text-black"
                      }`}
                      onClick={() => {
                        setSelectedSize(size);
                      }}
                    >
                      <p className="text-[12px] font-bold">{size}</p>
                    </div>
                  );
                })}
              </div>
            </div>
          )
        ) : null}
        {/* {index === 0 && from !== "right" && (
          <div className="text-center pl-2 text-red-600">
            <p className="text-[12px] font-bold">NOCH</p>{" "}
            <p className="font-bold text-[20px]">{freeCount}</p>
          </div>
        )} */}
        {showActionBtn ? (
          isSelected ? (
            <GrFormClose
              className="min-w-[24px] text-[24px] cursor-pointer"
              onClick={() => {
                handleUnSelect(index);
              }}
            />
          ) : (
            <BsFillPlusSquareFill
              className="text-[#6ea13b] min-w-[30px] text-[30px] cursor-pointer"
              onClick={() => {
                if (!isDisabled) {
                  handleSelect(title, stock, photo, price, selectedSize);
                }
              }}
            />
          )
        ) : null}
      </div>
    </div>
  );
};

const Section = ({ from = "landing", orderId = null }) => {
  const [searchParams] = useSearchParams();
  const ref = searchParams.get("ref") || "";
  const { selectedProducts, setSelectedProducts, setCompletedFirstStep } =
    useContext(ConnectContext);
  const [progress, setProgress] = useState(0);
  const [showProceedContainer, setShowProceedContainer] = useState(false);
  const navigation = useNavigate();

  useEffect(() => {
    let sumPrices = 0;
    for (let i = 0; i < selectedProducts.length; i++) {
      if (
        selectedProducts[i].title !==
        "Saugende Bettschutzeinlage / wiederverwendbar"
      ) {
        sumPrices += selectedProducts[i].price;
      }
    }
    const roundedPercent = int_round5((sumPrices / maxPrice) * 100);
    if (roundedPercent >= 80) {
      setShowProceedContainer(true);
    } else {
      setShowProceedContainer(false);
    }
    setProgress(roundedPercent);
  }, [selectedProducts]);

  return (
    <div
      className={`bg-[#f4f6fa] w-[100%] -lg:h-auto -lg:pt-[20px] lg:pt-[50px] ${
        from == "modal"
          ? "pb-[50px]"
          : "pb-[100px] h-[calc(100vh+350px)] min-h-[1100px]"
      }`}
    >
      <div className="max-w-[1300px] flex flex-row -lg:flex-col justify-center items-start -lg:items-center gap-x-8 -lg:space-x-0 -lg:space-y-8 mx-auto px-4 -sm:px-2">
        <div
          className={`w-full sticky z-10 bg-white lg:hidden ${
            from == "modal" ? "top-0" : "top-[70px]"
          }`}
        >
          <div className="flex flex-col justify-center items-center w-full z-10 bg-[#f4f6fa]">
            <div className="flex flex-row justify-start items-center my-4 w-full">
              <div
                className="flex flex-row justify-end items-center space-x-1 text-[#6ea13b] font-bold cursor-pointer"
                onClick={() => {
                  setSelectedProducts([]);
                }}
              >
                <AiOutlineCloseCircle />
                <span className="font-bold text-[#6ea13b]">Box leeren</span>
              </div>
            </div>
            <div className="w-full my-4 relative">
              <ProgressBar
                completed={progress}
                transitionDuration="0.3s"
                barContainerClassName="progressbar-container"
                labelClassName="progressbar-label"
              />
              <div className="absolute right-[20%] top-[-22px] flex flex-col justify-center items-end">
                <p className="text-[12px] text-gray-600">Min. 80%</p>
                <div className="border-r-[2px] border-black h-[32px]" />
              </div>
            </div>
          </div>
        </div>
        <div className="w-[50%] -lg:w-[100%]">
          <div className="flex flex-row justify-start items-center space-x-2 mt-4 mb-8">
            <img src={CartSVG} className="w-[24px] h-[24px]" />
            <span className="text-[20px]">
              {from == "modal"
                ? "Bearbeiten Sie Ihre Produkte"
                : "Wählen Sie Ihre Produkte"}
            </span>
          </div>
          {Products.map((product, index) => {
            let sumPrices = 0;
            let isDisable = false;
            for (let i = 0; i < selectedProducts.length; i++) {
              if (
                selectedProducts[i].title !==
                "Saugende Bettschutzeinlage / wiederverwendbar"
              ) {
                sumPrices += selectedProducts[i].price;
              }
            }
            if (sumPrices + product.price > maxPrice) {
              isDisable = true;
            }
            if (
              product.title === "Saugende Bettschutzeinlage / wiederverwendbar"
            ) {
              if (
                selectedProducts.find((selectedProduct) => {
                  return selectedProduct.title === product.title;
                })
              ) {
                isDisable = true;
              } else {
                isDisable = false;
              }
            }
            return (
              <ProductCard
                key={index}
                isModal={from == "modal" ? true : false}
                orderId={orderId}
                index={index}
                title={product.title}
                stock={product.stock}
                photo={product.photo}
                price={product.price}
                tooltipText={ProductsTooltips[index]}
                isDisabled={isDisable}
                handleSelect={(title, stock, photo, price, size) => {
                  // if (selectedProducts.length === 5) {
                  //   return;
                  // }
                  let products = [];
                  products.push({
                    title,
                    stock,
                    photo,
                    price,
                    size,
                  });
                  setSelectedProducts([...products, ...selectedProducts]);
                }}
              />
            );
          })}
        </div>
        <div className="w-[50%] -lg:w-[100%] flex flex-col justify-start items-start bg-[#f4f6fa] pb-[10px]">
          <div className="flex flex-col justify-center items-center w-full sticky top-[70px] bg-[#f4f6fa] -lg:hidden z-[9]">
            <div className="flex flex-row justify-between items-center my-4 w-full">
              <span>Ihr Warenkorb</span>
              <div
                className="flex flex-row justify-end items-center space-x-1 text-[#6ea13b] font-bold cursor-pointer"
                onClick={() => {
                  setSelectedProducts([]);
                }}
              >
                <AiOutlineCloseCircle />
                <span className="font-bold text-[#6ea13b]">Box leeren</span>
              </div>
            </div>
            <div className="w-full my-4 relative">
              <ProgressBar
                completed={progress}
                transitionDuration="0.3s"
                barContainerClassName="progressbar-container"
                labelClassName="progressbar-label"
              />
              <div className="absolute right-[20%] top-[-22px] flex flex-col justify-center items-end">
                <p className="text-[12px] text-gray-600">Min. 80%</p>
                <div className="border-r-[2px] border-black h-[32px]" />
              </div>
            </div>
          </div>

          {/* <ProductCard
            title={FreeProductJSON.title}
            stock={FreeProductJSON.stock}
            photo={FreeProductJSON.photo}
            isSelected={true}
            showActionBtn={false}
          /> */}
          <span className="mb-4 lg:hidden">Ihr Warenkorb</span>
          {selectedProducts.length > 0 &&
            selectedProducts.map((product, index) => {
              return (
                <ProductCard
                  key={index}
                  index={index}
                  title={product.title}
                  stock={product.stock}
                  photo={product.photo}
                  price={product.price}
                  productSize={product.size}
                  isSelected={true}
                  handleUnSelect={(idx) => {
                    let products = [...selectedProducts];
                    products.splice(idx, 1);
                    setSelectedProducts(products);
                  }}
                  from={"right"}
                />
              );
            })}
          {showProceedContainer && from != "modal" && (
            <div className="w-full flex flex-col justify-center items-center space-y-2 bg-white px-8 py-3 rounded-[10px]">
              <p className="text-[14px]">
                Sie haben den Mindestbestellwert erreicht.
              </p>
              <div
                className="w-full flex flex-row justify-center items-center rounded-[10px] py-2 bg-[#f98918] hover:bg-[#ffc04f] hover:text-black text-[14px] text-white font-bold cursor-pointer"
                onClick={() => {
                  setCompletedFirstStep(true);
                  if (ref) {
                    navigation(`/order/data-entry?ref=${ref}`);
                  } else {
                    navigation("/order/data-entry");
                  }
                }}
              >
                Weiter zur Dateneingabe
              </div>
            </div>
          )}
        </div>
      </div>
      <ReactTooltip
        id={`product0`}
        effect={"solid"}
        arrowColor={"transparent"}
        delayShow={150}
        className="max-w-[350px] -sm:max-w-[300px] p-4"
      >
        <div className="w-full flex flex-col justify-start items-start space-y-2">
          <div className="flex flex-col justify-start items-start space-y-1">
            <p className="text-[14px] font-bold font-Mulish text-[#6ea13b]">
              Produktbeschreibung
            </p>
            <div className="p-2 text-[12px]">
              Bettschutzauflage 85 x 90cm, wiederverwendbar ohne Flügeln 4-lagig
              (Stück) Hellblau, waschbar Saugfähigkeit: 2600 ml/qm
            </div>
          </div>
        </div>
      </ReactTooltip>
      <ReactTooltip
        id={`product1`}
        effect={"solid"}
        arrowColor={"transparent"}
        delayShow={150}
        className="max-w-[350px] -sm:max-w-[300px] p-4"
      >
        <div className="w-full flex flex-col justify-start items-start space-y-2">
          <div className="flex flex-col justify-start items-start space-y-1">
            <p className="text-[14px] font-bold font-Mulish text-[#6ea13b]">
              Produktbeschreibung
            </p>
            <div className="p-2 text-[12px]">
              <p>
                LyncMed Nitril Handschuhe mit Mikrotextur haben gegenüber
                Latexhandschuhen einen entscheidenden Vorteil. Das Material ist
                undurchlässiger gegenüber Fett, Öl und Chemikalien. Sie sind
                elastisch und schützen die Hände vor Bakterien, Schmutz und
                aggressiven Substanzen. Nitril kommt immer dann zum Einsatz,
                wenn eine Allergie gegen Latex vorliegt. Die Handschuhe lassen
                sich beidseitig verwenden und dienen in erster Linie zum
                Selbstschutz.
              </p>
            </div>
          </div>
          <div className="flex flex-col justify-start items-start space-y-1">
            <p className="text-[14px] font-bold font-Mulish text-[#6ea13b]">
              Eigenschaften
            </p>
            <div className="p-2 text-[12px] space-y-1">
              <p>Normen und Anforderungen</p>
              <p>– MDR (Medizinprodukteverordnung)</p>
              <p>– PSA-KAT 1</p>
              <p>
                Textur Mikrotextur mit zusätzlich texturierten Fingerspitzen
              </p>
              <p>Innenfläche: puderfrei – polymerisiert</p>
              <p>Nitrilkautschuk, Latexfrei</p>
              <p>Nicht steril</p>
              <p>Hoher Tragekomfort</p>
              <p>Allergieverträgliches Material</p>
              <p>Undurchlässiger gegenüber Fett, Öl und Chemikalien</p>
              <p>Dichtheit AQL: ≤1,5</p>
              <p>Farbe LyncMed Nitril Handschuhe mit Mikrotextur: Blau</p>
              <p>Form: Universell</p>
              <p>Verfügbare Größen: S | M | L | XL</p>
              <p>Hersteller: Lyncmed Medical Technology (Beijing) Co. Ltd.</p>
            </div>
          </div>
        </div>
      </ReactTooltip>
      <ReactTooltip
        id={`product2`}
        effect={"solid"}
        arrowColor={"transparent"}
        delayShow={150}
        className="max-w-[350px] -sm:max-w-[300px] p-4"
      >
        <div className="w-full flex flex-col justify-start items-start space-y-2">
          <div className="flex flex-col justify-start items-start space-y-1">
            <p className="text-[14px] font-bold font-Mulish text-[#6ea13b]">
              Produktbeschreibung
            </p>
            <div className="p-2 text-[12px] space-y-1">
              <p>
                Meditrade Latex-Fingerlinge Zur Abdeckung einzelner Finger zur
                Verabreichung von Zäpfchen, Auftragen von Cremes und einfachen
                Untersuchungen. Verwendung meist in Untersuchungs-, Pflege-,
                Hygiene- und Industriebereichen. Die Meditrade Latex-Fingerlinge
                mit Rollrand und glatter Oberfläche sind ein zuverlässiges und
                vielseitiges Produkt für den professionellen und privaten
                Gebrauch.
              </p>
              <p>
                Naturkautschuklatex, kolloidaler Schwefel, Accel TP, Perkacit
                ZDEC, aktives Zinkoxid, Demol-N, Wingstay-L oder Lowinox CPL,
                Ammoniakwasser oder TA-20, Titanweiß
              </p>
            </div>
            <p className="text-[14px] font-bold font-Mulish text-[#6ea13b]">
              Eigenschaften
            </p>
            <div className="p-2 text-[12px] space-y-1">
              <p>Frei von Silikon</p>
              <p>Unsteril</p>
              <p>Puderfrei</p>
              <p>Mit Rollrand und glatter Oberfläche</p>
              <p>Länge: ca. 70mm</p>
              <p>Wandstärke-Finger: ca. 0,11mm</p>
              <p>Größen: S, M, L, XL</p>
              <p>Farbe: weiß</p>
            </div>
          </div>
        </div>
      </ReactTooltip>
      <ReactTooltip
        id={`product3`}
        effect={"solid"}
        arrowColor={"transparent"}
        delayShow={150}
        className="max-w-[350px] -sm:max-w-[300px] p-4"
      >
        <div className="w-full flex flex-col justify-start items-start space-y-2">
          <div className="flex flex-col justify-start items-start space-y-1">
            <p className="text-[14px] font-bold font-Mulish text-[#6ea13b]">
              Produktbeschreibung
            </p>
            <div className="p-2 text-[12px]">
              <p>Alkoholisches Händedesinfektionsmittel auf Ethanolbasis.</p>
            </div>
            <p className="text-[14px] font-bold font-Mulish text-[#6ea13b]">
              Eigenschaften
            </p>
            <div className="p-2 text-[12px] space-y-1">
              <p>Rückfettend</p>
              <p>Wirkt bakterizid, levurozid und tuberkulozid</p>
              <p>
                Wirksam gegen Noro-Viren in 15 Sekunden und Corona-Viren
                (SARS-CoV-2 + VOC) in 30 Sekunden
              </p>
              <p>
                Getestet nach den Standartmethoden der DGHM/VAH und EN 1500/EN
                12791
              </p>
              <p>Sehr gut hautverträglich, auch bei häufiger Anwendung</p>
              <p>
                Reduzierung der bakteriellen transienten Hautflora in 30
                Sekunden
              </p>
              <p>
                Geeignet für den Lebensmittelbereich
                (HACCP-Verkehrsfähigkeitsbescheinigung)
              </p>
              <p>Desinfiziert chirurgisch in 1,5 Minuten</p>
              <p>Frei von Farb-und Parfümstoffen</p>
              <p>VAH-gelistet</p>
            </div>
          </div>
        </div>
      </ReactTooltip>
      <ReactTooltip
        id={`product4`}
        effect={"solid"}
        arrowColor={"transparent"}
        delayShow={150}
        className="max-w-[350px] -sm:max-w-[300px] p-4"
      >
        <div className="w-full flex flex-col justify-start items-start space-y-2">
          <div className="flex flex-col justify-start items-start space-y-1">
            <p className="text-[14px] font-bold font-Mulish text-[#6ea13b]">
              Eigenschaften
            </p>
            <div className="p-2 text-[12px] space-y-1">
              <p>
                alkoholisches Schnelldesinfektionsmittel zur Flächendesinfektion
              </p>
              <p>VAH-gelistet</p>
              <p>begrenzt viruzid PLUS</p>
              <p>für den Lebensmittelbereich geeignet (HACCP)</p>
              <p>
                für die Tränkung von Tüchern geeignet - diese finden Sie HIER
              </p>
              <p>
                wirkt bakterizid (inkl. MRSA), levurozid, tuberkulozid,
                mykobakterizid, und begrenzt viruzid PLUS (gemäß EN 14476)
              </p>
              <p>
                wirksam gegen Noro-, Adeno- und Corona-Viren (SARS-CoV-2 + VOC)
              </p>
              <p>Aldehyd- und QAV-frei</p>
              <p>praxisgerechte Einwirkzeiten</p>
              <p>100g Lösung enthalten 50g Ethanol</p>
              <p>Medizinproduktklasse IIa</p>
            </div>
          </div>
        </div>
      </ReactTooltip>
      <ReactTooltip
        id={`product8`}
        effect={"solid"}
        arrowColor={"transparent"}
        delayShow={150}
        className="max-w-[350px] -sm:max-w-[300px] p-4"
      >
        <div className="w-full flex flex-col justify-start items-start space-y-2">
          <div className="flex flex-col justify-start items-start space-y-1">
            <p className="text-[14px] font-bold font-Mulish text-[#6ea13b]">
              Produktbeschreibung
            </p>
            <div className="p-2 text-[12px]">
              <p>
                Dieser Einmalmundschutz bietet eine optimale Passform und
                ermöglicht ungehindertes Atmen.
              </p>
            </div>
          </div>
          <div className="flex flex-col justify-start items-start space-y-1">
            <p className="text-[14px] font-bold font-Mulish text-[#6ea13b]">
              Eigenschaften
            </p>
            <div className="p-2 text-[12px] space-y-1">
              Details auf einen Blick
              <p>– glasfaser-, und latexfrei</p>
              <p>– bakterielle Filtration (BFE) {">"}98%</p>
              <p>– sterilisierbar: EO; R</p>
              <p>– Typ II</p>
              <p>– hypoallergen und luftdurchlässig</p>
              <p>
                – einstellbarer Nasenbügel für einen anatomisch korrekten Sitz
              </p>
              <p>– aus 3-lagigem Vliesstoff</p>
              <p>
                Die MaiMed® FM Comfort OP-Mundschutz ist für den Einsatz als
                Mundschutz im Krankenhaus, im Kosmetik- und Wellnessbereich und
                in der Lebensmittelindustrie geeignet. Die Schutzmaske bietet
                Schutz vor Kontamination.
              </p>
            </div>
          </div>
        </div>
      </ReactTooltip>
      <ReactTooltip
        id={`product5`}
        effect={"solid"}
        arrowColor={"transparent"}
        delayShow={150}
        className="max-w-[350px] -sm:max-w-[300px] p-4"
      >
        <div className="w-full flex flex-col justify-start items-start space-y-2">
          <div className="flex flex-col justify-start items-start space-y-1">
            <p className="text-[14px] font-bold font-Mulish text-[#6ea13b]">
              Produktbeschreibung
            </p>
            <div className="p-2 text-[12px] space-y-1">
              <p>
                BeeSana Comflock Super Krankenunterlage mit Saugkörper aus
                Zellstoffflocken für eine rasche und vollflächige
                Feuchtigkeitsaufnahme. Das Trockenvlies mit rutschfester
                Außenfolie gewährt eine bedarfsgerechte und effiziente
                Anwendung. Krankenunterlagen helfen im häuslichen Pflegebereich,
                den Pflegekomfort zu erhöhen, die tägliche Arbeit zu erleichtern
                und nicht zuletzt dienen sie als Bettschutz um die Wäscheberge
                zu reduzieren. BeeSana Comflock Super Krankenunterlage sind bei
                uns als Lagen- und Flockenunterlagen in unterschiedlichen
                Qualitäten erhältlich
              </p>
            </div>
          </div>
          <div className="flex flex-col justify-start items-start space-y-1">
            <p className="text-[14px] font-bold font-Mulish text-[#6ea13b]">
              Eigenschaften
            </p>
            <div className="p-2 text-[12px] space-y-1">
              <p>Normen und Anforderungen:</p>
              <p>– ISO 11948-1</p>
              <p>Saugkörper aus Zellstoffflocken</p>
              <p>Bedarfsgerechte und effiziente Anwendung</p>
              <p>Schnelle und vollflächige Feuchtigkeitsaufnahme</p>
              <p>Rutschfester Außenfolie</p>
              <p>Rückseitenschicht: Polyethylen-Schicht</p>
              <p>Innenseite: Vliesstoff</p>
              <p>Erhöht den Pflegekomfort im Pflegebereich</p>
              <p>Dient als Bettschutz um die Wäscheberge zu reduzieren</p>
              <p>Für den Einmalgebrauch</p>
              <p>Maße = Saugleistung /Inhalt je Packung</p>
              <p>– 60 x 90 cm = ca. 1590ml (25 Stk./Pack.)</p>
              <p>Haltbarkeit: 5 Jahre</p>
            </div>
          </div>
        </div>
      </ReactTooltip>
      <ReactTooltip
        id={`product6`}
        effect={"solid"}
        arrowColor={"transparent"}
        delayShow={150}
        className="max-w-[350px] -sm:max-w-[300px] p-4"
      >
        <div className="w-full flex flex-col justify-start items-start space-y-2">
          <div className="flex flex-col justify-start items-start space-y-1">
            <p className="text-[14px] font-bold font-Mulish text-[#6ea13b]">
              Produktbeschreibung
            </p>
            <div className="p-2 text-[12px] space-y-1">
              <p>
                Meditrade PE-Einmalschürze geblockt aus LDPE Schutz der Kleidung
                bei pflegerischen Tätigkeiten und in allen Bereichen mit hohen
                Hygieneanforderungen, u. a. in Großküchen und Krankenhäusern.
              </p>
            </div>
          </div>
          <div className="flex flex-col justify-start items-start space-y-1">
            <p className="text-[14px] font-bold font-Mulish text-[#6ea13b]">
              Eigenschaften
            </p>
            <div className="p-2 text-[12px] space-y-1">
              <p>Normen und Anforderungen:</p>
              <p>Medizinprodukt Klasse I gemäß Verordnung (EU) 2017/745</p>
              <p>EN ISO 13485:2016</p>
              <p>Mit Verschlussbändern an der Taille für guten Sitz</p>
              <p>Angenehmer Tragekomfort durch leichtes Material</p>
              <p>Strapazierfähig und feuchtigkeitsundurchlässig</p>
              <p>Geblockt, einzeln abreißbar</p>
              <p>Zum einmaligen Gebrauch</p>
              <p>Material: LDPE (Polyethylen)</p>
              <p>Unsteril</p>
              <p>Dichte: 16µ</p>
              <p>Größe:</p>
              <p>125 x 81 cm</p>
              <p>Farbe: weiß</p>
              <p>50 Stück</p>
            </div>
          </div>
        </div>
      </ReactTooltip>
      <ReactTooltip
        id={`product7`}
        effect={"solid"}
        arrowColor={"transparent"}
        delayShow={150}
        className="max-w-[350px] -sm:max-w-[300px] p-4"
      >
        <div className="w-full flex flex-col justify-start items-start space-y-2">
          <div className="flex flex-col justify-start items-start space-y-1">
            <p className="text-[14px] font-bold font-Mulish text-[#6ea13b]">
              Produktbeschreibung
            </p>
            <div className="p-2 text-[12px] space-y-1">
              <p>Schürze aus PVC</p>
              <p>Mittlerer bis hoher Spritzschutz.</p>
              <p>Schutz gegen ein breites Spektrum von Chemikalien.</p>
              <p>
                Hervorragende Flexibilität und Beständigkeit gegen Chemikalien,
                Fette, Öle,
              </p>
              <p>
                Schmiermittel, Abschürfungen und Stiche. Einfach zu reinigen.
              </p>
              <p>Maße: 84 x 112 cm</p>
            </div>
          </div>
        </div>
      </ReactTooltip>
      <ReactTooltip
        id={`product9`}
        effect={"solid"}
        arrowColor={"transparent"}
        delayShow={150}
        className="max-w-[350px] -sm:max-w-[300px] p-4"
      >
        <div className="w-full flex flex-col justify-start items-start space-y-2">
          <div className="flex flex-col justify-start items-start space-y-1">
            <p className="text-[14px] font-bold font-Mulish text-[#6ea13b]">
              Produktbeschreibung
            </p>
            <div className="p-2 text-[12px] space-y-1">
              Die KingFa FFP2 NR D Atemschutzmaske, guter Atemkomfort, ohne
              Ventil ist eine 5-lagige Maske, die besonders vor (infektiöse)
              Aerosole, Staub und allgemeinen Schwebeteilchen schützt. Die gegen
              Dolomitstaub wirksame FFP2 Maske KingFa FFP2 NR D bietet einen
              angenehmen Atemkomfort, lässt sich angenehm tragen und schützt Sie
              auch wirkungsvoll gegen Aerosole.
            </div>
            <div className="p-2 text-[12px] space-y-1">
              <p>Normen und Anforderungen:</p>
              <p>PSA Kategorie III</p>
              <p>CE0598</p>
              <p>EN 149:2001 + A1:2009</p>
              <p>FFP2 Standard + Feinstaubschutz</p>
              <p>Leicht zu tragen, sehr geringer Atemwiderstand</p>
              <p>Ergonomisch angepasste Passform</p>
              <p>Angenehme Ohrschlaufen</p>
              <p>Mit Nasenrückenpolster</p>
              <p>Technische Informationen zur FFP2 NR D Atemschutzmaske:</p>
              <p>Partikel-Filtrationseffizienz (PFE) ≥ 94%</p>
              <p>Atemwiderstand (Inhalation, 30L/min) : ≤ 0.7mbar</p>
              <p>Atemwiderstand (Inhalation, 95L/min) : ≤ 2.4mbar</p>
              <p>Atemwiderstand (Ausatmung, 160L/min) : ≤ 3.0mbar</p>
              <p>Entflammbarkeit: ≤ 5S</p>
              <p>
                Totraum: ≤ 1,0% ( vorteilhafte Strömungseigenschaften durch
                Dichtung über der Nasenbrücke )
              </p>
              <p>5-lagig</p>
            </div>
          </div>
        </div>
      </ReactTooltip>
      <ReactTooltip
        id={`product10`}
        effect={"solid"}
        arrowColor={"transparent"}
        delayShow={150}
        className="max-w-[350px] -sm:max-w-[300px] p-4"
      >
        <div className="w-full flex flex-col justify-start items-start space-y-2">
          <div className="flex flex-col justify-start items-start space-y-1">
            <p className="text-[14px] font-bold font-Mulish text-[#6ea13b]">
              Produktbeschreibung
            </p>
            <div className="p-2 text-[12px] space-y-1">
              MaiMed’s MyClean DS Flowpack ist ein gebrauchsfertiges
              Desinfektionstuch, welches zur Anwendung auf wischbeständigen
              Oberflächen geeignet ist. Die Tränklösung des Tuchs ist
              bakterizid, levurozid, mykobakterizid und begrenzt viruzid,
              inklusive HBV/HIV, BVDV (HCV), Vaccinia, Influenza/Grippe.
            </div>
            <div className="p-2 text-[12px] space-y-1">
              <p>Gebrauchsfertige, alkoholische, aldehyd- und QAV-freie </p>
              <p>
                Desinfektionstücher zur Desinfektion alkoholbeständiger
                Oberflächen von nicht invasiven Medizinprodukten und Oberflächen
                im medizinischen Umfeld, Lebensmittelbereich, öffentlichen
                Einrichtungen und in der Industrie
              </p>
              <p>
                Ausgeschlossen sind Oberflächen von Instrumenten bzw.
                semikritischen und kritischen Medizinprodukten
              </p>
              <p>Für den professionellen Gebrauch</p>
              <p>
                Tränklösung wirkt bakterizid, levurozid, mykobakterizid,
                begrenzt viruzid PLUS (inkl. Corona-Viren (SARS-CoV-2 + VOC))
              </p>
              <p>
                Tücher sind 100% biologisch abbaubar mit optimaler
                Wirkstoffabgabe
              </p>
            </div>
          </div>
        </div>
      </ReactTooltip>
    </div>
  );
};

export default Section;
