import React, { useEffect } from 'react';
import Header from '../components/Header';
import Section from './Section';
import Footer from '../components/Footer';
import Menu from '../components/Menu';

const Thank = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
  }, [])
  return (
    <div className='bg-[#f4f6fa]'>
      <Menu id={3} />
      <Header />
      <Section />
      <Footer />
    </div>
  )
}

export default Thank;