import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  SET_MESSAGE,
  REFRESH_TOKEN
} from "./types";

import AuthService from "../services/auth.service";
import TokenService from "../services/token.service";

export const register = (company='', firstname, lastname, email, password, iban) => (dispatch) => {
  return AuthService.register(company, firstname, lastname, email, password, iban).then(
    (response) => {
      dispatch({
        type: REGISTER_SUCCESS,
        payload: {
          email: email,
          firstname: firstname
        }
      });
      dispatch({
        type: SET_MESSAGE,
        payload: {
          success: true,
          message: response.data.message
        }
      });
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch({ type: REGISTER_FAIL });
      dispatch({
        type: SET_MESSAGE,
        payload: {
          success: false,
          message: message
        }
      });
      return Promise.reject();
    }
  );
};

export const login = (email, password) => (dispatch) => {
  return AuthService.login(email, password).then(
    (response) => {
      if (response.data.accessToken) {
        TokenService.setUser(response.data);
        dispatch({ type: LOGIN_SUCCESS, payload: { user: response.data } });
        return Promise.resolve(response.data);
      } else {
        // pending account
        dispatch({
          type: SET_MESSAGE,
          payload: {
            success: false,
            message: response.data.message
          }
        });
        if (response.data.verify) {
          dispatch({
            type: REGISTER_SUCCESS,
            payload: response.data.verify
          });
        }
        return Promise.reject();
      }
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch({ type: LOGIN_FAIL });
      dispatch({
        type: SET_MESSAGE,
        payload: {
          success: false,
          message: message
        }
      });
      return Promise.reject();
    }
  );
};

export const logout = () => (dispatch) => {
  AuthService.logout();
  dispatch({ type: LOGOUT });
};

export const refreshToken = (accessToken) => (dispatch) => {
  dispatch({ type: REFRESH_TOKEN, payload: accessToken })
}

export const verifyUser = (confirmationCode) => (dispatch) => {
  return AuthService.verify(confirmationCode).then(
    (response) => {
      if (response.data.success) {
        return Promise.resolve(response.data.message);
      } else {
        return Promise.reject(response.data.message);
      }
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch({
        type: SET_MESSAGE,
        payload: {
          success: false,
          message: message
        }
      });
      return Promise.reject(message);
    }
  )
}

export const resend = (verify) => (dispatch) => {
  return AuthService.resend(verify).then(
    (response) => {
      if (response.data.success) {
        return Promise.resolve(response.data.message);
      } else {
        return Promise.reject(response.data.message);
      }
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch({
        type: SET_MESSAGE,
        payload: {
          success: false,
          message: message
        }
      });
      return Promise.reject(message);
    }
  )
}

export const forgotPassword = (email) => (dispatch) => {
  return AuthService.forgot(email).then(
    (response) => {
      if (response.data.success) {
        dispatch({
          type: SET_MESSAGE,
          payload: {
            success: true,
            message: response.data.message
          }
        });
        return Promise.resolve();
      } else {
        dispatch({
          type: SET_MESSAGE,
          payload: {
            success: false,
            message: response.data.message
          }
        });
        return Promise.reject();
      }
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch({
        type: SET_MESSAGE,
        payload: {
          success: false,
          message: message
        }
      });
      return Promise.reject();
    }
  )
}

export const resetPassword = (token, password) => (dispatch) => {
  return AuthService.reset(token, password).then(
    (response) => {
      if (response.data.success) {
        dispatch({
          type: SET_MESSAGE,
          payload: {
            success: true,
            message: response.data.message
          }
        });
        return Promise.resolve();
      } else {
        dispatch({
          type: SET_MESSAGE,
          payload: {
            success: false,
            message: response.data.message
          }
        });
        return Promise.reject();
      }
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch({
        type: SET_MESSAGE,
        payload: {
          success: false,
          message: message
        }
      });
      return Promise.reject();
    }
  )
}