import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import VerifySVG from "../../assets/svgs/verify.svg";
import { verifyUser, resend } from "../../actions/auth";
import { CgSpinner } from "react-icons/cg";
import { useTranslation } from "react-i18next";
import Header from "../components/Header";
import Footer from "../components/Footer";

const Confirmation = (props) => {
  const { t } = useTranslation();
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const [showConfirmationText, setShowConfirmationText] = useState("");
  const [showLoginButton, setShowLoginButton] = useState(null);
  const { verify, user: currentUser } = useSelector((state) => state.auth);
  const { confirmationCode } = useParams();

  useEffect(() => {
    if (currentUser) {
      navigation("/order/select");
    } else {
      if (confirmationCode) {
        dispatch(verifyUser(confirmationCode))
          .then((successMsg) => {
            setShowConfirmationText(successMsg);
          })
          .catch((errorMsg) => {
            setShowConfirmationText(errorMsg);
          });
        setShowLoginButton(true);
      } else {
        if (!verify.email) {
          navigation("/login");
        } else {
          setShowLoginButton(false);
          setShowConfirmationText(
            `${t("auth.confirm.text1")} ${verify.email}. ${t(
              "auth.confirm.text2"
            )}`
          );
        }
      }
    }
  }, [confirmationCode, verify, currentUser]);

  const handleResendEmail = () => {
    if (verify) {
      setShowConfirmationText("");
      dispatch(resend(verify))
        .then((successMsg) => {
          setShowConfirmationText(t("auth.confirm.text3"));
        })
        .catch((errorMsg) => {
          setShowConfirmationText(errorMsg);
        });
    }
  };

  return (
    <div className="bg-[#f4f6fa]">
      <Header />
      <div className="h-[calc(100vh-231px)] min-h-[900px] flex bg-gray-100">
        <div className="flex flex-col justify-start items-center max-w-[1750px] mx-auto min-w-[350px] p-8">
          <div className="flex flex-col justify-center items-center w-[100%] h-[calc(100vh-280px)] min-h-[500px] space-y-4">
            <img src={VerifySVG} className="w-[100px]" />
            <div className="flex flex-col justify-center items-center space-y-4 max-w-[400px] -sm:w-[100%] -sm:px-4">
              {showConfirmationText ? (
                <span className="font-RNSSanzMedium text-[16px] text-[#282828] w-[100%] text-center">
                  {showConfirmationText}
                </span>
              ) : (
                <CgSpinner
                  className={`text-[#6ea13b] text-[40px] animate-spin`}
                />
              )}
              {showLoginButton ? (
                <a
                  className="text-[#6ea13b] text-[18px] font-SenSans cursor-pointer underline uppercase"
                  onClick={() => navigation("/login")}
                >
                  {t("auth.confirm.button.back")}
                </a>
              ) : (
                <div
                  className="px-8 py-3 bg-[#6ea13b] rounded-lg text-white font-RNSSanzBold cursor-pointer"
                  onClick={() => handleResendEmail()}
                >
                  {t("auth.confirm.button.resend")}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Confirmation;
