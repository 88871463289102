import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PasswordPNG from "../../assets/password.png";
import CheckButton from "react-validation/build/button";
import { CgSpinner } from "react-icons/cg";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import { forgotPassword, resetPassword } from "../../actions/auth";
import { required, validEmail, vpassword, vpasswordConfirm } from "../../utils";
import { useTranslation } from "react-i18next";
import Header from "../components/Header";
import Footer from "../components/Footer";

const InputEmail = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const form = useRef();
  const checkBtn = useRef();
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const onChangeEmail = (e) => {
    const email = e.target.value;
    setEmail(email);
  };

  const handleForgotPassword = (e) => {
    e.preventDefault();
    setIsLoading(true);
    form.current.validateAll();
    if (checkBtn.current.context._errors.length === 0) {
      dispatch(forgotPassword(email))
        .then(() => {
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex flex-col justify-center items-center space-y-4 max-w-[340px] -sm:w-[100%] -sm:px-4">
      <span className="font-RNSSanzMedium text-[16px] text-[#282828] w-[100%] text-center">
        {t("auth.forgot.text")}
      </span>
      <Form
        onSubmit={handleForgotPassword}
        ref={form}
        className="flex flex-col justify-center itesm-center w-full"
      >
        <div className="form-group">
          <label
            htmlFor="email"
            className="text-[12px] font-RNSSanzMedium pb-1 text-[#8A9499]"
          >
            {t("auth.forgot.email")}
          </label>
          <Input
            type="text"
            className="form-control !bg-white"
            name="email"
            value={email}
            onChange={onChangeEmail}
            validations={[required, validEmail]}
            placeholder={t("auth.forgot.placeholder")}
          />
        </div>
        <div className="form-group w-[100%] flex flex-row justify-center">
          <button className="!w-[150px] !h-[46px] font-RNSSanzBold !text-[18px]">
            {isLoading ? (
              <CgSpinner className={`text-white text-[30px] animate-spin`} />
            ) : (
              <span className="text-white">
                {t("auth.forgot.button.submit")}
              </span>
            )}
          </button>
        </div>
        <CheckButton style={{ display: "none" }} ref={checkBtn} />
      </Form>
    </div>
  );
};

const InputPassword = ({ resetCode }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const form = useRef();
  const checkBtn = useRef();
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const onChangeNewPassword = (e) => {
    const newPassword = e.target.value;
    setNewPassword(newPassword);
  };

  const onChangeConfirmPassword = (e) => {
    const confirmPassword = e.target.value;
    setConfirmPassword(confirmPassword);
  };

  const handleResetPassword = (e) => {
    e.preventDefault();
    setIsLoading(true);
    form.current.validateAll();
    if (checkBtn.current.context._errors.length === 0) {
      dispatch(resetPassword(resetCode, newPassword))
        .then(() => {
          setIsLoading(false);
          navigation("/login");
        })
        .catch(() => {
          setIsLoading(false);
          navigation("/password_reset");
        });
    } else {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex flex-col justify-center items-center space-y-8 max-w-[340px] -sm:w-[100%] -sm:px-4">
      <span className="font-RNSSanzMedium text-[16px] text-[#282828] w-full text-center">
        {t("auth.reset.text")}
      </span>
      <Form
        onSubmit={handleResetPassword}
        ref={form}
        className="flex flex-col justify-center itesm-center space-y-4 w-full"
      >
        <div className="form-group">
          <label
            htmlFor="password"
            className="text-[12px] font-RNSSanzMedium pb-1 text-[#8A9499]"
          >
            {t("auth.reset.new")}
          </label>
          <Input
            type="password"
            className="form-control !bg-white"
            name="password"
            value={newPassword}
            onChange={onChangeNewPassword}
            validations={[required, vpassword]}
            placeholder={t("auth.reset.new")}
          />
        </div>
        <div className="form-group">
          <label
            htmlFor="confirmPassword"
            className="text-[12px] font-RNSSanzMedium pb-1 text-[#8A9499]"
          >
            {t("auth.reset.confirm")}
          </label>
          <Input
            type="password"
            className="form-control !bg-white"
            name="confirmPassword"
            value={confirmPassword}
            onChange={onChangeConfirmPassword}
            validations={[required, vpasswordConfirm]}
            placeholder={t("auth.reset.confirm")}
          />
        </div>
        <div className="form-group w-full flex flex-row justify-center">
          <button className="!w-[150px] !h-[46px] font-RNSSanzBold !text-[18px]">
            {isLoading ? (
              <CgSpinner className={`text-white text-[30px] animate-spin`} />
            ) : (
              <span className="text-white">
                {t("auth.reset.button.submit")}
              </span>
            )}
          </button>
        </div>
        <CheckButton style={{ display: "none" }} ref={checkBtn} />
      </Form>
    </div>
  );
};

const PasswordReset = (props) => {
  const navigation = useNavigate();
  const { resetCode } = useParams();
  const { user: currentUser } = useSelector((state) => state.auth);

  useEffect(() => {
    if (currentUser) {
      navigation("/order/select");
    }
  }, [currentUser]);

  return (
    <div className="bg-[#f4f6fa]">
      <Header />
      <div className="h-[calc(100vh-231px)] min-h-[900px] flex bg-gray-100">
        <div className="flex flex-col justify-start items-center max-w-[1750px] mx-auto min-w-[350px] p-8">
          <div className="flex flex-col justify-center items-center w-[100%] h-[calc(100vh-280px)] min-h-[500px] space-y-4">
            <img src={PasswordPNG} className="w-[100px]" />
            {resetCode ? (
              <InputPassword resetCode={resetCode} />
            ) : (
              <InputEmail />
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PasswordReset;
