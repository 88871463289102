import React from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { CgSpinner } from "react-icons/cg";
import CloseSVG from '../../../assets/svgs/close.svg';

const ConfirmModal = ({
  title,
  description,
  confirmTitle,
  cancelTitle,
  handleConfirm,
  handleCancel,
  isLoading,
}) => {
  return (
    <div
      className={`fixed h-full w-full top-0 left-0 bg-[rgba(138,148,153,0.7)] bg-opacity-80 flex items-start justify-center z-30 overflow-y-scroll overflow-x-hidden detail-modal`}
    >
      <div className="relative mx-auto rounded-lg bg-white my-[50px] -md:w-[96%] md:w-[370px]">
        <div className="w-full min-h-[60px] px-8 py-4 border-b-[1px] border-[#e1e4e6] bg-white rounded-tl-lg rounded-tr-lg flex flex-row justify-between items-center">
          <div className="text-[20px] font-RNSSanzBold">
            {title}
          </div>
          <div
            className="flex flex-row justify-center items-center bg-[#0000000d] w-[30px] h-[30px] rounded-full cursor-pointer"
            onClick={handleCancel}
          >
            <img src={CloseSVG} className="w-[20px] h-[20px]" />
          </div>
        </div>
        <div className="flex flex-col justify-center items-center text-center font-RNSSanzBold py-12">
          <span className="max-w-[300px]">{description}</span>
        </div>
        <div className="flex flex-row justify-center items-center py-4 font-SenSans text-[14px] space-x-2 w-full border-t border-t-gray-200">
          <div
            className="w-[100px] py-2 flex flex-row justify-center items-center border border-[#6ea13b] rounded-lg bg-white text-[#6ea13b] cursor-pointer"
            onClick={handleCancel}
          >
            {cancelTitle}
          </div>
          <div
            className="w-[100px] py-2 flex flex-row justify-center items-center border border-[#6ea13b] rounded-lg bg-[#6ea13b] text-white cursor-pointer"
            onClick={handleConfirm}
          >
            {isLoading ? (
              <CgSpinner className={`text-white text-[20px] animate-spin`} />
            ) : (
              confirmTitle
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmModal;
