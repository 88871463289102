import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { HiMenu } from "react-icons/hi";
import { FaTimes } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import LOGO from "../../assets/logo.png";
import HeaderDropDown from "./HeaderDropDown";
import { logout } from "../../actions/auth";
import BlankAvatarSVG from "../../assets/svgs/blank.svg";

const Header = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const [openSidebar, setOpenSidebar] = useState(false);
  const { user: currentUser } = useSelector((state) => state.auth);

  const userMenuLists = [
    { id: 1, title: "Bestellung erstellen", href: "/order/select" },
    { id: 2, title: "Meine Bestellungen", href: "/history" },
  ];

  const adminMenuList = [
    { id: 1, title: "Auftragsverwaltung", href: "/admin/order-list" },
    { id: 2, title: "Benutzerverwaltung", href: "/admin/user-list" },
    { id: 3, title: "Versandhistorie", href: "/admin/shipping-history" },
  ];

  const partnerMenuList = [
    { id: 1, title: "Auftragsverwaltung", href: "/admin/order-list" },
    // { id: 2, title: "Benutzerverwaltung", href: "/admin/user-list" },
    { id: 2, title: "Bestellung erstellen", href: "/order/select" },
  ];

  const menu =
    currentUser?.role === "admin"
      ? adminMenuList
      : currentUser?.role === "partner"
      ? partnerMenuList
      : userMenuLists;

  return (
    <>
      {/* sidebar menu */}
      {openSidebar && (
        <div
          className="fixed h-[100vh] inset-0 z-30 bg-black opacity-70"
          onClick={() => setOpenSidebar(false)}
        />
      )}
      <div
        className={`fixed z-40 top-0 bottom-0 right-0 overflow-x-hidden py-16 -sm:py-12 transition-all duration-[0.3s] -sm:transition-all -sm:duration-[0.5s] overflow-y-scroll
      ${
        openSidebar
          ? `px-12 -sm:!w-[100%] -sm:px-8 -sm:!min-w-[100%]`
          : "!w-[0px] min-w-0"
      }`}
        style={{
          width: "100%",
          background: "rgba(255, 255, 255, 0.8)",
        }}
      >
        <FaTimes
          className="absolute top-[20px] right-[30px] -sm:right-[15px] text-[#6ea13b] text-[30px] text-bolder cursor-pointer"
          onClick={() => {
            setOpenSidebar(false);
          }}
        />
        <div className="flex flex-col justify-center items-center py-[30px] text-white">
          {menu.map((item, index) => {
            return (
              <div
                key={index}
                className="text-[#6ea13b] text-[18px] font-bold p-4"
                onClick={() => {
                  setOpenSidebar(false);
                  navigation(item.href);
                }}
              >
                {item.title}
              </div>
            );
          })}
          {!currentUser && (
            <div
              className="p-4 cursor-pointer text-black uppercase font-bold"
              onClick={() => {
                navigation("/login");
              }}
            >
              {t("header.login")}
            </div>
          )}
        </div>
      </div>

      {/* header bar */}
      <div
        className={`fixed inset-x-0 top-0 py-4 px-8 -sm:px-4 ease-in-out duration-500 z-20`}
        style={{
          background: "rgba(255, 255, 255, 1)",
          boxShadow:
            "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px",
        }}
      >
        <div className="mx-auto flex flex-row justify-between items-center text-white max-w-[1250px]">
          <div
            className="cursor-pointer flex flex-row justify-start items-center space-x-2"
            onClick={() => {
              window.location.href = "https://pflegeimkarton.de/";
            }}
          >
            <img
              src={LOGO}
              className="inline h-[40px] cursor-pointer"
              onClick={() => {
                navigation("/order/select");
              }}
            />
            <span className="text-[18px] font-bold text-[#6ea13b]">
              Pflege im Karton
            </span>
          </div>
          <div className="cursor-pointer">
            <div className="flex flex-row justify-end items-center md:hidden space-x-2">
              {currentUser && (
                <div className="flex flex-row justify-start items-center space-x-2">
                  <img
                    src={
                      currentUser && currentUser.avatar
                        ? currentUser.avatar
                        : BlankAvatarSVG
                    }
                    className="rounded-full w-[34px] h-[34px] border border-[#6ea13b]"
                  />
                  <HeaderDropDown
                    logout={() => {
                      dispatch(logout());
                    }}
                  />
                </div>
              )}
              <HiMenu
                className="inline text-[30px] cursor-pointer text-[#6ea13b]"
                onClick={() => {
                  setOpenSidebar(true);
                }}
              />
            </div>
            <div className="flex flex-row justify-center items-center space-x-12 -lg:space-x-3 text-[14px] -md:hidden">
              {menu.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="text-[#6ea13b] text-[14px] font-bold"
                    onClick={() => {
                      navigation(item.href);
                    }}
                  >
                    {item.title}
                  </div>
                );
              })}
              {currentUser ? (
                <div className="flex flex-row justify-start items-center space-x-2">
                  <img
                    src={
                      currentUser && currentUser.avatar
                        ? currentUser.avatar
                        : BlankAvatarSVG
                    }
                    className="rounded-full w-[34px] h-[34px] border border-[#6ea13b]"
                  />
                  <HeaderDropDown
                    logout={() => {
                      dispatch(logout());
                      navigation("/login");
                    }}
                  />
                </div>
              ) : (
                <div className="flex flex-row justify-start items-center space-x-4">
                  <div
                    className="cursor-pointer text-[16px] text-[#6ea13b] font-bold underline"
                    onClick={() => {
                      navigation("/login");
                    }}
                  >
                    {t("header.login")}
                  </div>
                  <div
                    className="cursor-pointer text-[16px] text-[#6ea13b] font-bold underline"
                    onClick={() => {
                      navigation("/register");
                    }}
                  >
                    {t("header.register")}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
