import React, { useState } from "react";
import DatePicker from "react-datepicker";
import CloseSVG from "../../../assets/svgs/close.svg";
import "react-datepicker/dist/react-datepicker.css";

const PDFAllownloadModal = ({ handleCancel, handleConfirm }) => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  return (
    <div
      className={`fixed h-full w-full top-0 left-0 bg-[rgba(138,148,153,0.7)] bg-opacity-80 flex items-start justify-center z-30 overflow-y-scroll overflow-x-hidden detail-modal`}
    >
      <div className="relative mx-auto rounded-lg bg-white my-[50px] -md:w-[96%] md:w-[400px]">
        <div className="w-full min-h-[60px] px-8 py-4 border-b-[1px] border-[#e1e4e6] bg-white rounded-tl-lg rounded-tr-lg flex flex-row justify-between items-center">
          <div className="text-[20px] font-RNSSanzBold">Herunterladen</div>
          <div
            className="flex flex-row justify-center items-center bg-[#0000000d] w-[30px] h-[30px] rounded-full cursor-pointer"
            onClick={handleCancel}
          >
            <img src={CloseSVG} className="w-[20px] h-[20px]" />
          </div>
        </div>
        <div className="flex flex-col justify-center items-center text-center font-RNSSanzBold py-12 px-8">
          <p className="text-left w-full text-[12px]">Bitte wählen Sie das Datum aus</p>
          <DatePicker
            dateFormat="dd.MM.yyyy"
            className="w-full border border-gray-300 rounded-lg px-4 py-2 text-[12px]"
            selected={selectedDate}
            onChange={(date) => setSelectedDate(date)}
          />
        </div>
        <div className="grid grid-cols-2 gap-4 py-4 font-SenSans text-[14px] w-full border-t border-t-gray-200 px-8">
          <div
            className="w-full py-2 flex flex-row justify-center items-center border border-[#6ea13b] rounded-lg bg-white text-[#6ea13b] cursor-pointer"
            onClick={handleCancel}
          >
            Stornieren
          </div>
          <div
            className="w-full py-2 flex flex-row justify-center items-center border border-[#6ea13b] rounded-lg bg-[#6ea13b] text-white cursor-pointer"
            onClick={() => handleConfirm(selectedDate)}
          >
            Herunterladen
          </div>
        </div>
      </div>
    </div>
  );
};

export default PDFAllownloadModal;
