import axios from "axios";

// Create an instance of Axios with a specific baseURL
const api = axios.create({
  // baseURL: 'https://box.pflegeimkarton.de', // production backend url
  baseURL: "https://api.v2.pflegeimkarton.de", // development backend url
  // baseURL: 'http://localhost:8080', // local
  headers: {
    "Content-Type": "application/json",
  },
});

export default api;
