import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import setupInterceptors from "./services/setupInterceptors";
import App from "./app";
import store from "./store";
import "./index.css";
import './i18n.js';

const root = ReactDOM.createRoot(document.getElementById("root"));

const Routing = () => {
  return (
    <Provider store={store}>
      <App />
    </Provider>
  );
};

setupInterceptors(store)

root.render(<Routing />);
