import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useSearchParams,
} from "react-router-dom";
import RequireAuthLayout from "./pages/auth/RequireAuthLayout";
import RequireUserLayout from "./pages/auth/RequireUserLayout";
import RequireAdminLayout from "./pages/auth/RequireAdminLayout";
import Landing from "./pages/landing/";
import Data from "./pages/data";
import Sign from "./pages/sign";
import Thank from "./pages/thank";
import Login from "./pages/auth/Login";
import Register from "./pages/auth/Register";
import RegisterForPartner from "./pages/auth/RegisterForPartner";
import Confirmation from "./pages/auth/Confirmation";
import PasswordReset from "./pages/auth/PasswordReset";
import UserHistory from "./pages/history/UserHistory";
import ShippingHistory from "./pages/history/ShippingHistory";
import UserProfile from "./pages/profile";
import ConnectProvider from "./provider/ConnectProvider";

import OrderList from "./pages/admin/orders";
import UserList from "./pages/admin/users";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import OrderPdfDocument from "./pages/admin/orders/OrderPdfDocument";
import InsurancePdfDocument from "./pages/admin/orders/InsurancePdfDocument";
// import NewPdfDocument from "./pages/admin/orders/ThirdDocument";

const Notification = () => {
  const { message } = useSelector((state) => state.message);
  useEffect(() => {
    if (message && message.id) {
      if (message.success)
        toast.success(message.message, {
          position: toast.POSITION.TOP_CENTER,
          theme: "colored",
          hideProgressBar: false,
          autoClose: 5000,
        });
      else
        toast.error(message.message, {
          position: toast.POSITION.TOP_CENTER,
          theme: "colored",
          hideProgressBar: false,
          autoClose: 5000,
        });
    }
  }, [message && message.id]);
  return <></>;
};

const RedirectForRef = () => {
  const [searchParams] = useSearchParams();
  const ref = searchParams.get('ref') || '';

  return <Navigate to={`/order/select?ref=${ref}`} replace />;
}

const App = () => {
  return (
    <>
      <Router>
        <RequireAuthLayout>
          <ConnectProvider>
            <Routes>
              {/* auth routing */}
              <Route path="/" element={<RedirectForRef />} />
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route path="/partner/register" element={<RegisterForPartner />} />
              <Route exact path="/confirmation" element={<Confirmation />} />
              <Route
                exact
                path="/confirmation/:confirmationCode"
                element={<Confirmation />}
              />
              <Route exact path="/password_reset" element={<PasswordReset />} />
              <Route
                exact
                path="/password_reset/:resetCode"
                element={<PasswordReset />}
              />

              {/* order routing */}
              <Route
                path="/order/select"
                element={
                  // <RequireUserLayout>
                    <Landing />
                  // </RequireUserLayout>
                }
              />
              <Route
                path="/order/data-entry"
                element={
                  // <RequireUserLayout>
                    <Data />
                  // </RequireUserLayout>
                }
              />
              <Route
                path="/order/signature"
                element={
                  // <RequireUserLayout>
                    <Sign />
                  // </RequireUserLayout>
                }
              />
              <Route
                path="/order/thank-you"
                element={
                  // <RequireUserLayout>
                    <Thank />
                  // </RequireUserLayout>
                }
              />
              <Route
                path="/history"
                element={
                  <RequireUserLayout>
                    <UserHistory />
                  </RequireUserLayout>
                }
              />
              <Route
                path="/profile"
                element={
                  <RequireUserLayout>
                    <UserProfile />
                  </RequireUserLayout>
                }
              />

              {/* admin routing */}
              <Route
                path="/admin/order-list"
                element={
                  <RequireAdminLayout>
                    <OrderList />
                  </RequireAdminLayout>
                }
              />
              <Route
                path="/admin/user-list"
                element={
                  <RequireAdminLayout>
                    <UserList />
                  </RequireAdminLayout>
                }
              />
              <Route
                path="/admin/shipping-history"
                element={
                  <RequireUserLayout>
                    <ShippingHistory />
                  </RequireUserLayout>
                }
              />

              <Route path="/pdf-test-1" element={<OrderPdfDocument />} />
              <Route path="/pdf-test-2" element={<InsurancePdfDocument />} />
              {/* <Route path="/pdf-test-3" element={<NewPdfDocument />} /> */}
            </Routes>
          </ConnectProvider>
        </RequireAuthLayout>
      </Router>
      <ToastContainer />
      <Notification />
    </>
  );
};

export default App;
