import api from './api';

const getUsers = (searchParam, pageSize, pageNumber, column, sortDirection) => {
  return api.get("/api/admin/getUsers", {
    params: {
      searchParam: searchParam,
      pageSize: pageSize,
      pageNumber: pageNumber,
      column: column,
      sortDirection: sortDirection
    }
  });
}

const addUser = (payload) => {
  return api.post("/api/admin/addUser", payload);
}

const updateUser = (payload) => {
  return api.post("/api/admin/updateUser", payload);
}

const deleteUser = (payload) => {
  return api.post("/api/admin/deleteUser", payload);
}

export default {
  getUsers,
  addUser,
  updateUser,
  deleteUser,
}