import React from 'react';
import {
  TbCircleNumber1,
  TbCircleNumber2,
  TbCircleNumber3,
  TbCircleNumber4
} from 'react-icons/tb';

const Menu = ({ id = 0 }) => {
  const selectIcon = (id) => {
    switch (id) {
      case 0:
        return <TbCircleNumber1 className={'min-w-[24px] h-[24px]'} />;
      case 1:
        return <TbCircleNumber2 className={'min-w-[24px] h-[24px]'} />;
      case 2:
        return <TbCircleNumber3 className={'min-w-[24px] h-[24px]'} />;
      case 3:
        return <TbCircleNumber4 className={'min-w-[24px] h-[24px]'} />;

    }
  }
  return (
    <div className='flex flex-row justify-between items-center pt-[100px] px-[20px] max-w-[1250px] mx-auto'>
      <div
        className={`flex justify-start items-center space-x-2 px-4 -sm:px-3 py-2 rounded-lg font-bold text-[14px] -md:text-[12px] ${id === 0 ? 'bg-[#6ea13b] text-white' : 'text-gray-600'}`}
      >
        {selectIcon(0)}
        <p className='-sm:hidden whitespace-nowrap'>Box wählen</p>
      </div>
      <div className="w-full h-10 dots"></div>
      <div
        className={`flex justify-start items-center space-x-2 px-4 -sm:px-3 py-2 rounded-lg font-bold text-[14px] -md:text-[12px] ${id === 1 ? 'bg-[#6ea13b] text-white' : 'text-gray-600'}`}
      >
        {selectIcon(1)}
        <p className='-sm:hidden whitespace-nowrap'>Dateneingabe</p>
      </div>
      <div className="w-full h-10 dots"></div>
      <div
        className={`flex justify-start items-center space-x-2 px-4 -sm:px-3 py-2 rounded-lg font-bold text-[14px] -md:text-[12px] ${id === 2 ? 'bg-[#6ea13b] text-white' : 'text-gray-600'}`}
      >
        {selectIcon(2)}
        <p className='-sm:hidden whitespace-nowrap'>Unterschrift</p>
      </div>
      <div className="w-full h-10 dots"></div>
      <div
        className={`flex justify-start items-center space-x-2 px-4 -sm:px-3 py-2 rounded-lg font-bold text-[14px] -md:text-[12px] ${id === 3 ? 'bg-[#6ea13b] text-white' : 'text-gray-600'}`}
      >
        {selectIcon(3)}
        <p className='-sm:hidden whitespace-nowrap'>Fertig</p>
      </div>
    </div>
  )
}

export default Menu;