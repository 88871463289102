import React from 'react';

const tabList = [
  { id: 0, title: "Allgemein" },
  { id: 1, title: "Kennwort" }
]

const ProfileNavigation = ({ active, onClick, children }) => {
  return (
    <div className='w-full flex flex-col justify-start items-start bg-white rounded-[20px] p-12 -md:p-4 overflow-y-auto profile-container'>
      <span className="text-[26px] font-RNSSanzBold text-[#3D474D] -lg:w-full font-bold mb-8">Mein Profil</span>
      <div className='flex flex-row justify-start items-center space-x-12 -lg:space-x-6 w-full mb-8'
        style={{
          boxShadow: 'inset 0 -2px 0 #edf1f2'
        }}
      >
        {tabList.map(tab => {
          return (
            <div
              key={tab.id} 
              className={`font-RNSSanzBold cursor-pointer text-[14px] font-bold py-2 border-b-[2px] ${tab.id === active ? 'border-b-[#6ea13b] text-[#6ea13b]' : 'text-[#8A9499] border-b-transparent'}`}
              onClick={() => onClick(tab.id)}
            >
              {tab.title}
            </div>
          )
        })}
      </div>
      {children}
    </div>
  )
}

export default ProfileNavigation;